import { UserService } from 'src/app/services/user.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { PaysService } from 'src/app/services/pays.service';
import { Settings } from 'src/app/app.settings.model';
import { AppSettings } from 'src/app/app.settings';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroupDirective, NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-pays',
  templateUrl: './add-pays.component.html',
  styleUrls: ['./add-pays.component.scss']
})
export class AddPaysComponent {

  selectedFiles?: FileList;
  selectedFileNames: string[] = [];

  progressInfos: any[] = [];
  message: string[] = [];

  previews: string[] = [];
  imageInfos?: Observable<any>;
  settings: Settings;
  granite = true;
  fileData: File = null;
  bam: boolean = false;
  etatPadding: boolean = true;
  constructor(
    private _snckBar: SnackBarService,
    public dialogRef: MatDialogRef<AddPaysComponent>,
    public userService:UserService,
    public appSettings:AppSettings,
    private fb: FormBuilder,
    private paysService: PaysService)
     {
    this.settings = this.appSettings.settings;     
  }
  ngOnInit(): void {
   
  }
  paysForm =  this.fb.group({
    libelle: ['',  {
      validators: [
      Validators.required,
    ]},],
    
   
   
  });
  onSubmit(){
    //  this.etatPadding = false;
         this.paysService.addPays(this.paysForm.value).subscribe(res => {
           this.dialogRef.close();
           this._snckBar.openSnackBar("pays Ajouté!!", "Fermer");
          //  this.etatPadding = true;

         });
   
    
  }
  close(): void {
    this.dialogRef.close();
  }
  }

