import { SnackBarService } from './../../services/snack-bar.service';
import { CommuneService } from './../../services/commune.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-commune',
  templateUrl: './delete-commune.component.html',
  styleUrls: ['./delete-commune.component.scss']
})
export class DeleteCommuneComponent {

  constructor(public dialogRef: MatDialogRef<DeleteCommuneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private communeService: CommuneService, private _snackBar: SnackBarService) { }

  ngOnInit(): void {
  }
  onClickYes(){
    this.communeService.deleteCommune(this.data.id).subscribe(res => {
      this.dialogRef.close();
      this._snackBar.openSnackBar("Supression Reuissie!!", "Fermer");
    });
  }

  onClickNo(){
    this.dialogRef.close();
  }
}
