import { UserService } from 'src/app/services/user.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ZoneService } from '../services/zone.service';
import { FormControl, Validators } from '@angular/forms';
import { Package } from 'src/staticData/package';
import { Passport } from 'src/staticData/passport';
import { Produit } from 'src/staticData/produit';

@Component({
  selector: 'app-add-plage',
  templateUrl: './add-plage.component.html',
  styleUrls: ['./add-plage.component.scss']
})
export class AddPlageComponent implements OnInit {
  public passportId: any =0
  constructor(public dialogRef: MatDialogRef<AddPlageComponent>,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,private produitService: ProduitService,private fb: FormBuilder, private zoneService: ZoneService, private _snackBar: SnackBarService) { }
  etatPadding: boolean = true;
  produits: any[] = [];
  public package=[]
  public produit=[]
  zone: any;
  public passport= []
  zoneForm = this.fb.group({

   
    total: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
    passport_id: ['', []],
    package_id: ['', []],
    produit_id: ['', []],
    id_zone: ['', []],
  });

  ngOnInit(): void {
    this.getPassport()
    this.getZones();
    this.allproduit()
  }

  onSubmit(){
    this.etatPadding = false;
    this.id_zone.setValue(this.zone[0]._id);
    this.zoneService.addPlage(this.zoneForm.value).subscribe(res => {
      console.log("body",this.zoneForm.value)
      this._snackBar.openSnackBar("Plage Ajouter!!", "Fermer");
      this.etatPadding = true;
      this.dialogRef.close();
    })
  }
  getZones(){
    this.zoneService.getZone(this.data.id).subscribe(res => {
      this.zone = res;
     console.log("zone bella",this.zone[0]._id);

    })
  }
 
  get total(){
    return this.zoneForm.get('total');
  }
  get id_zone(){
    return this.zoneForm.get('id_zone');
  }
  get produit_id(){
    return this.zoneForm.get('produit_id');
  }

  public getPassport(){
    this.package= []
    this.produit= []
    this.passport= Passport
    
  }

  getPackageByPassportId(event: any){
    this.passportId= event.target.value
 
    this.package= []
    this.produit= []
    for (let i = 0; i < Package.length; i++) {
      if(Package[i].passportId== Number(event.target.value) ){
        this.package[i]= Package[i]
      }
    }
    let c= this.package.filter((item: any)=> item != undefined)
    this.package= c
      //  this.isReady= true
  }

 allproduit(){
  this.produits  = Produit
  }
  getProduitByPackages(event: any){
    this.produit  = Produit


  }


  getProduitByPackage(event: any){
    for (let i = 0; i < Produit.length; i++) {
      if(Produit[i].packageId== Number(event.target.value)){
        this.produit[i]= Produit[i]
      }
    }
    let c= this.produit.filter((item: any)=> item != undefined)
    this.produit= c


  }

}
