import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { PrintService } from 'src/app/services/print.service';
import { UserService } from 'src/app/services/user.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Produit } from '../all-produit/Produit.model';
import { CommuneService } from 'src/app/services/commune.service';
import { CommandeService } from 'src/app/services/commande.service';
import { SharedService } from 'src/app/services/shared.service';
import { DetailsComponent } from '../commande-attente/details/details.component';

@Component({
  selector: 'app-dossier-attente',
  templateUrl: './dossier-attente.component.html',
  styleUrls: ['./dossier-attente.component.scss']
})
export class DossierAttenteComponent implements OnInit {

  page = 1;
  count = 0;
  pageSize = 3;
  pageSizes = [3, 6, 9];
  public isObject= false
  title = '';
  public  commandeAttente:Produit[] = []; 
  public commandeTrouvee: any
  public commandeReferenceAchercher= ''
     // commandes: any[] = []; 
public commande: any
dataSource = new MatTableDataSource<PeriodicElement>(null);
subscription: Subscription;
displayedColumns: string[] = ['dateCommande', 'montantTotal','status','Action','details'];

@ViewChild(MatPaginator) paginator: MatPaginator;
table: DatatableComponent
@ViewChild(MatSort, { static: false }) sort: MatSort;


public settings: Settings;
  
constructor(
      private commandeService: CommandeService,private userService:UserService,
      public sharedService: SharedService, 
      public print: PrintService, 
      private dialog: MatDialog,
      public appSettings:AppSettings
  ) {
    this.settings = this.appSettings.settings; 
}

ngOnInit(): void {
this.getPaginatedAllCommandeAttenteLivraisons()
}

getPaginatedAllCommandeAttenteLivraisons(){
  const params = this.getRequestParams(this.title, this.page, this.pageSize);
   this.commandeService.paginatedCommandeAttenteForDistributeur(params.page,this.userService.getUserDetails()._id).subscribe((res : any)=> {
        this.commandeAttente = res.docs;
        this.count = res.totalDocs;
        this.page = params.page;

        console.log(this.commandeAttente)
   });
  }

  getRequestParams(searchTitle: string  , page: number, pageSize: number): any {
    let params: any = {};
    if (searchTitle) {
      params[`title`] = searchTitle;
    }
    if (page) {
      params[`page`] = page - 1;
    }
    if (pageSize) {
      params[`size`] = pageSize;
    }
    return params;
  }
  chercherUneCommandeParSaReference(){
    if(this.commandeReferenceAchercher== ''){
      this.getPaginatedAllCommandeAttenteLivraisons()
    }
    else{
    
      const obj={
        reference: this.commandeReferenceAchercher
      }
      this.commandeService.chercherUneCommandeParSaReference(obj)
      .subscribe((data: any)=>{
        this.isObject= true
        this.commandeAttente = data
        console.log("test",this.commandeAttente)
      })
    }
  }
  handlePageChange(event: number): void {
    this.page = event;

    this.commandeService.paginatedCommandeAttenteForDistributeur(this.page,this.userService.getUserDetails()._id).subscribe((res : any)=> {
      this.commandeAttente = res.docs;
      this.count = res.totalDocs;
    });
  }



  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.getPaginatedAllCommandeAttenteLivraisons();
  

  }

  public onPageChanged(event){
    this.page = event;
    this.getPaginatedAllCommandeAttenteLivraisons();
    if(this.settings.fixedHeader){      
        document.getElementById('main-content').scrollTop = 0;
    }
    else{
        document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
    }
  } 


   getAllDetailCommande() {
    this.commandeService.AllCommandeAttenteDistributeur(this.userService.getUserDetails()._id).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource <PeriodicElement> (data); 
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
     this.sharedService.tableauProduitParCommandeId= data
 
      return data
    })}
voirDetailCommande(id){
  this.sharedService.panierId= id
   this.dialog.open(DetailsComponent, {
    width: '1200px',
    height: '200px',
    data: {id: id}
   })
}



}
export interface PeriodicElement {
  dateCommande: string
  montantTotal: string
  status: string
  Action: string
  details: string
  }