import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subscription, timer } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { ZoneService } from 'src/app/services/zone.service';
import { AddRoleComponent } from '../add-role/add-role.component';
import { DeleteRoleComponent } from '../delete-role/delete-role.component';


@Component({
  selector: 'app-all-role',
  templateUrl: './all-role.component.html',
  styleUrls: ['./all-role.component.scss']
})
export class AllRoleComponent implements OnInit {

  constructor( @Inject(DOCUMENT) private document: Document, public dialogRef: MatDialogRef<AllRoleComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialog, private userServices: UserService,private zoneServices: ZoneService) { }
  zone: any[] = [];
  zones: any[] = [];
  subscription: Subscription;
  p: number = 1;
  ngOnInit(): void {
    this.subscription =  timer(0, 3000).subscribe(res => {
      this.getZones();
    });
   
  }

  getZones(){
    this.userServices.viewRole(this.data.id).subscribe(res => {
      this.zone = res;
     
      console.log("mes produits ", this.zone)
    })
  }
  
  addRole(id){
    this.dialog.open(AddRoleComponent, {
      data: {id: id}
    })
  }
  deleteRole(id,user_id){
    this.dialog.open(DeleteRoleComponent, {
      data: {id: id,user_id:user_id}
    })
  }

}
