import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../backend';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaysService {
  ap = `${baseUrl}zones`;
  constructor(private http: HttpClient) { }

  public addPays(pays: any): Observable<any>{
    return this.http.post<any>(`${this.ap}/addPays`, pays).pipe(
      retry(3)
    );
  }

  public updatePays(id: any, pays): Observable<any>{
    return this.http.put<any>(`${this.ap}/updatePays/${id}`, pays).pipe(
      retry(3)
    );
  }
 
  public getAllPays(): Observable<any>{
    return this.http.get<any>(`${this.ap}/getAllPays`).pipe(
      retry(3)
    );
  }

  public deletePays(id: any): Observable<any>{
    return this.http.delete<any>(`${this.ap}/deletePays/${id}`).pipe(
      retry(3)
    );
  }

  public getPaysById(id: any): Observable<any>{
    return this.http.get<any>(`${this.ap}/getPaysById/${id}`).pipe(
      retry(3)
    );
  }
}
