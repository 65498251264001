import { Settings } from 'src/app/app.settings.model';
import { AppSettings } from './../../app.settings';
import { UserService } from 'src/app/services/user.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, timer } from 'rxjs';
import { AddPlageComponent } from 'src/app/add-plage/add-plage.component';
import { AddZoneComponent } from 'src/app/add-zone/add-zone.component';
import { DeleteZoneComponent } from 'src/app/delete-zone/delete-zone.component';
import { ZoneService } from 'src/app/services/zone.service';
import { UpdateZoneComponent } from 'src/app/update-zone/update-zone.component';
import { ViewPlageComponent } from 'src/app/view-plage/view-plage.component';

@Component({
  selector: 'app-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.scss']
})
export class ZoneComponent implements AfterViewInit ,OnInit {
  subscription: Subscription;
  page = 1;
  count = 0;
  public settings: Settings;
  pageSize = 6;
  pageSizes = [6, 12, 18];
  title = '';
  public zone: []=[]
  public isReady= false
  displayedColumns: string[] = [ 'ville','commune', 'localite','action','a','b'];
    constructor(private zoneService:ZoneService,private userService: UserService,private dialog: MatDialog,public appSettings:AppSettings){
      this.settings = this.appSettings.settings; 
    }
 

  dataSource = new MatTableDataSource<PeriodicElement>(null);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngAfterViewInit() {
    

  }
  ngOnInit(): void {
    // this.getZones()
    this.subscription =  timer(0, 3000).subscribe(res => {
      //  this.getAllOrders();
       
    });
  
  }



  getRequestParams(searchTitle: string  , page: number, pageSize: number): any {
    let params: any = {};
  
    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }
  
    if (pageSize) {
      params[`size`] = pageSize;
    }
    return params;
  }



 

 

  getAllOrders() {
    this.zoneService.getZoness().subscribe((data: any) => {
      console.log(data)
      this.dataSource = new MatTableDataSource <PeriodicElement> (data); //pass the array you want in the table
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      return data
    })}


    addZone(){
      this.dialog.open(AddZoneComponent, {
        width: '400px',
         height: '500px',
       
      })
    }
    addPlage(){
      this.dialog.open(AddPlageComponent, {
        width: '400px',
        height: '380px',
       
      })
    }
    viewPlage(id){
      this.dialog.open(ViewPlageComponent, {
        width: '800px',
        height: '380px',
        data: {id: id}
       
      })
    }
    updateZone(id){
      this.dialog.open(UpdateZoneComponent, {
        width: '400px',
        height: '380px',
        data: {id: id}
      })
    }
    deleteZone(id){
      this.dialog.open(DeleteZoneComponent, {
       
        data: {id: id}
      })
    }

}

export interface PeriodicElement {
  ville: string;
  commune: string;
  localite: string;
  action: string;
  "a": string;
  "b": string;
}





