import { Settings } from './../../app.settings.model';
import { AppSettings } from './../../app.settings';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, timer } from 'rxjs';
import { AddStockComponent } from 'src/app/add-stock/add-stock.component';
import { AddZoneComponent } from 'src/app/add-zone/add-zone.component';
import { DeleteStockComponent } from 'src/app/delete-stock/delete-stock.component';
import { CommandeService } from 'src/app/services/commande.service';
import { ZoneService } from 'src/app/services/zone.service';
import { UpdateStockComponent } from 'src/app/update-stock/update-stock.component';
import { ViewPlageComponent } from 'src/app/view-plage/view-plage.component';

@Component({
  selector: 'app-all-stock',
  templateUrl: './all-stock.component.html',
  styleUrls: ['./all-stock.component.scss']
})

export class AllStockComponent implements OnInit {
  page = 1;
  count = 0;
  pageSize = 3;
  public isReady= false
  title = '';
  public numberOfTimer=0
  public settings: Settings;

  subscription: Subscription;
  public stock: []=[]
  pageSizes = [3, 6, 9];

  displayedColumns: string[] = ['fournisseur', 'produit', 'quantite','action','a'];
    constructor(private userService: UserService,private zoneService:ZoneService,private stockService:CommandeService,private dialog: MatDialog, public appSettings:AppSettings){
      this.settings = this.appSettings.settings; 

    }
 

  dataSource = new MatTableDataSource<PeriodicElement>(null);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngAfterViewInit() {
  }

  ngOnInit(): void {
    this.subscription =  timer(0, 3000).subscribe(res => {
    this.getStock()
    this.numberOfTimer++
    });
  
  }
 

    getStock(){
      const params = this.getRequestParams(this.title, this.page, this.pageSize);
      this.stockService.allPaginatedStock(this.userService.getUserDetails()._id, params.page).subscribe((res: any) => {
            this.stock = res.docs;
            this.count = res.totalDocs;
            this.page = params.page;
            if(this.numberOfTimer== 1){
              this.isReady= true
            }
       });
      }

      getRequestParams(searchTitle: string  , page: number, pageSize: number): any {
        let params: any = {};
      
        if (searchTitle) {
          params[`title`] = searchTitle;
        }
      
        if (page) {
          params[`page`] = page - 1;
        }
      
        if (pageSize) {
          params[`size`] = pageSize;
        }
        return params;
      }

      handlePageChange(event: number): void {
        this.page = event; 
        this.stockService.allPaginatedStock(this.userService.getUserDetails()._id,this.page).subscribe((res : any)=> {
          this.stock = res.docs;
          this.count = res.totalDocs;
        });
      }

      retrieveTutorials(): void {
        const params = this.getRequestParams(this.title, this.page, this.pageSize);
         this.stockService.allPaginatedStock(this.userService.getUserDetails()._id,this.page).subscribe((res : any)=> {
              this.stock = res.docs;
               this.count = res.totalDocs;
               this.page = params.page;
          });
      }


  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.retrieveTutorials();
  }

  public onPageChanged(event){
    this.page = event;
    this.retrieveTutorials();
    if(this.settings.fixedHeader){      
        document.getElementById('main-content').scrollTop = 0;
    }
    else{
        document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
    }
  } 

    addZone(){
      this.dialog.open(AddZoneComponent, {
        width: '400px',
        height: '380px',
       
      })
    }

    addStock(){
      this.dialog.open(AddStockComponent, {
        width: '400px',
        height: '400px',
       
      })
    }

    viewPlage(id){
      this.dialog.open(ViewPlageComponent, {
        data: {id: id}
       
      })
    }
    
    updateStock(id){
      this.dialog.open(UpdateStockComponent, {
        width: '400px',
        height: '380px',
        data: {id: id}
      })
    }
    deleteStock(id){
      this.dialog.open(DeleteStockComponent, {
       
        data: {id: id}
      })
    }

}

export interface PeriodicElement {
  fournisseur: string;
  produit: string;
  quantite: string;
  prix: string;
  action: string;
  a: string;
 
 
 
}


