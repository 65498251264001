import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
 public  tailleTableau:any
 public taille: number 
 public panierId: any
 nombreStatutPourCommande: number
 positionLine: any
 public tableauProduitParCommandeId: any  
 public historiqueStatutTab: any
public nbOfEtape=0
 public positionPerCommade= false
  constructor() { 
    this.tailleTableau= JSON.parse(localStorage.getItem('tableauToAdd')) || []
    this.taille= this.tailleTableau.length 
    this.tableauProduitParCommandeId= []
  }
}
