import { SharedService } from 'src/app/services/shared.service';
import { CommandeService } from 'src/app/services/commande.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Subscription, timer, Observable } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrintService } from '../services/print.service';
import { UserService } from '../services/user.service';
import { AppSettings } from '../app.settings';
import { SnackBarService } from '../services/snack-bar.service';

@Component({
  selector: 'app-valider-dossier',
  templateUrl: './valider-dossier.component.html',
  styleUrls: ['./valider-dossier.component.scss']
})
export class ValiderDossierComponent implements OnInit {

  constructor( 
    private commandeService: CommandeService,

  private userService:UserService,
  public dialogRef: MatDialogRef<ValiderDossierComponent>,
 @Inject(MAT_DIALOG_DATA) public data: any,
  public sharedService: SharedService, 
  public print: PrintService, 
  private dialog: MatDialog,
  public appSettings:AppSettings,
  private _snackBar: SnackBarService) { }

  ngOnInit(): void {
    console.log("data",this.data.id);
    console.log("data commande",this.data.idCommande);

  }
  onClickYes(){
    this.commandeService.validerProduit(this.data.id,this.data.idCommande).subscribe(res => {
      this.dialogRef.close();
      this._snackBar.openSnackBar("Validation Reuissie!!", "Fermer");
    });
  }

  onClickNo(){
    this.dialogRef.close();
  }

}
