import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-delete-role',
  templateUrl: './delete-role.component.html',
  styleUrls: ['./delete-role.component.scss']
})
export class DeleteRoleComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private userService: UserService, private _snackBar: SnackBarService) { }

  ngOnInit(): void {
  }
  onClickYes(){
    this.userService.deleteRole(this.data.id,this.data.user_id).subscribe(res => {
      // this.router.navigate(['/boutiques/shopingByCategorie']);
       this._snackBar.openSnackBar("Role Annuler!!", "Quitter");
       this.dialogRef.close();
     })
  }

  onClickNo(){
    this.dialogRef.close();
  }

}
