import { baseUrl } from './../../backend';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { DeleteCategorieComponent } from 'src/app/delete-categorie/delete-categorie.component';
import { PrintService } from 'src/app/services/print.service';
import { ProduitService } from 'src/app/services/produit.service';
import { AddCategorieComponent } from '../../add-categorie/add-categorie.component';
import { Categorie } from './Categorie.model';
import { UpdateCategorieComponent } from '../../update-categorie/update-categorie.component';


@Component({
  selector: 'app-all-categorie',
  templateUrl: './all-categorie.component.html',
  styleUrls: ['./all-categorie.component.scss']
})
export class AllCategorieComponent implements OnInit {
// produits: any[] = []; 
public categories: Categorie[]
public isReady= false
public settings: Settings;
page = 1;
count = 0;
pageSize = 6;
pageSizes = [6, 12, 18];
title = '';
public baseUrl
constructor(private cdr: ChangeDetectorRef,public print: PrintService, private dialog: MatDialog,public appSettings:AppSettings,private produitService:ProduitService) {
 this.settings = this.appSettings.settings; 
 this.baseUrl= baseUrl
}

ngOnInit(): void {
 this.getProduits();
}
getProduits(){
  const params = this.getRequestParams(this.title, this.page, this.pageSize);
 this.produitService.getPaginatedCategorie(params.page).subscribe((res : any)=> {
   this.categories = res.docs;
      this.count = res.totalDocs;
      this.page = params.page;
      this.isReady = true
 });
}
addCategorie(){
  this.dialog.open(AddCategorieComponent, {
  
   
  })
}
deleteCategorie(id){
  this.dialog.open(DeleteCategorieComponent, {
    data: {id: id}
   
  })
}
upadteCategorie(id){
  
  this.dialog.open(UpdateCategorieComponent, {
    data: {id: id}
   
  })
}


retrieveTutorials(): void {
  const params = this.getRequestParams(this.title, this.page, this.pageSize);
   this.produitService.getPaginatedCategorie(this.page).subscribe((res : any)=> {
      this.categories = res.docs;
         this.count = res.totalDocs;
         this.page = params.page;
    });
}

getRequestParams(searchTitle: string  , page: number, pageSize: number): any {
  let params: any = {};

  if (searchTitle) {
    params[`title`] = searchTitle;
  }

  if (page) {
    params[`page`] = page - 1;
  }

  if (pageSize) {
    params[`size`] = pageSize;
  }

  return params;
}

handlePageChange(event: number): void {
  this.page = event;
  this.produitService.getCategorie().subscribe((res : any)=> {
    this.categories = res.docs;
    this.count = res.totalDocs;
  });
}

handlePageSizeChange(event: any): void {
  this.pageSize = event.target.value;
  this.page = 1;
  this.retrieveTutorials();
}



public onPageChanged(event){
  this.page = event;
  this.retrieveTutorials();
  if(this.settings.fixedHeader){      
      document.getElementById('main-content').scrollTop = 0;
  }
  else{
      document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
  }
}

}
