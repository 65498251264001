import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { ConfirmationUpdateComponent } from './confirmation-update/confirmation-update.component';
import { UpdateComponent } from './update/update.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { AgmCoreModule } from '@agm/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true               
};
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'; 
import { SharedModule } from './shared/shared.module';
import { PipesModule } from './theme/pipes/pipes.module';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { AppSettings } from './app.settings';
import { SidenavComponent } from './theme/components/sidenav/sidenav.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProduitComponent } from './produit/produit.component';
import { AddProduitComponent } from './add-produit/add-produit.component';
import { DeleteProduitComponent } from './delete-produit/delete-produit.component';
import { AllProduitComponent } from './pages/all-produit/all-produit.component';
import { AllCategorieComponent } from './pages/all-categorie/all-categorie.component';
import { AddCategorieComponent } from './add-categorie/add-categorie.component';
import { DeleteCategorieComponent } from './delete-categorie/delete-categorie.component';
import { UpdateCategorieComponent } from './update-categorie/update-categorie.component';
import { LoginComponent } from './login/login.component';
import { UpdateProduitComponent } from './update-produit/update-produit.component';
import { AddZoneComponent } from './add-zone/add-zone.component';
import { DeleteZoneComponent } from './delete-zone/delete-zone.component';
import { UpdateZoneComponent } from './update-zone/update-zone.component';
import { AddPlageComponent } from './add-plage/add-plage.component';
import { ViewPlageComponent } from './view-plage/view-plage.component';
import { DeletePlageComponent } from './delete-plage/delete-plage.component';
import { AllStockComponent } from './pages/all-stock/all-stock.component';
import { AddStockComponent } from './add-stock/add-stock.component';
import { UpdateStockComponent } from './update-stock/update-stock.component';
import { DeleteStockComponent } from './delete-stock/delete-stock.component';
import { AllChauffeurComponent } from './pages/all-chauffeur/all-chauffeur.component';
import { AddChauffeurComponent } from './add-chauffeur/add-chauffeur.component';
import { DeleteChauffeurComponent } from './delete-chauffeur/delete-chauffeur.component';
import { UpdateChauffeurComponent } from './update-chauffeur/update-chauffeur.component';
import { TransportComponent } from './transport/transport.component';
import { AddTransportComponent } from './add-transport/add-transport.component';
import { DeleteTransportComponent } from './delete-transport/delete-transport.component';
import { AddPaysComponent } from './pages/add-pays/add-pays.component';
import { UpdatePaysComponent } from './pages/update-pays/update-pays.component';
import { AllPaysComponent } from './pages/all-pays/all-pays.component';
import { AllCommuneComponent } from './pages/all-commune/all-commune.component';
import { UpdateCommuneComponent } from './pages/update-commune/update-commune.component';
import { AddCommuneComponent } from './pages/add-commune/add-commune.component';
import { AddRegionComponent } from './pages/add-region/add-region.component';
import { UpdateRegionComponent } from './pages/update-region/update-region.component';
import { AllRegionComponent } from './pages/all-region/all-region.component';
import { DeletePaysComponent } from './pages/delete-pays/delete-pays.component';
import { DeleteRegionComponent } from './pages/delete-region/delete-region.component';
import { DeleteCommuneComponent } from './papages/delete-commune/delete-commune.component';
import { AllStatutComponent } from './pages/all-statut/all-statut.component';
import { AddStatutComponent } from './pages/add-statut/add-statut.component';
import { UpdateStatutComponent } from './pages/update-statut/update-statut.component';
import { DeleteStatutComponent } from './pages/delete-statut/delete-statut.component';
import { UpdatePasswordComponent } from './pages/update-password/update-password.component';
import { ImageLoadedDirective } from './directives/image-loaded.directive';
import { AllUserComponent } from './pages/all-user/all-user.component';
import { UserByTelephoneComponent } from './pages/user-by-telephone/user-by-telephone.component';
import { RechercheByUserComponent } from './pages/recherche-by-user/recherche-by-user.component';
import { AddUserComponent } from './pages/add-user/add-user.component';
import { AllRoleComponent } from './pages/all-role/all-role.component';

import { UpdateUserComponent } from './pages/update-user/update-user.component';
import { AddRoleComponent } from './pages/add-role/add-role.component';
import { DeleteRoleComponent } from './pages/delete-role/delete-role.component';
import { SouscriptionComponent } from './pages/souscription/souscription.component';
import { AllZoneComponent } from './all-zone/all-zone.component';
import { ZoneComponent } from './pages/zone/zone.component';
import { ZonesComponent } from './pages/zones/zones.component';
import { AllZonesComponent } from './all-zones/all-zones.component';
import { AllQuartierComponent } from './all-quartier/all-quartier.component';
import { DetailsComponent } from './pages/commande-attente/details/details.component';
import { DetailCommandeAttenteComponent } from './pages/commande-attente/detail-commande-attente/detail-commande-attente.component';
import { CommandeAttenteComponent } from './pages/commande-attente/commande-attente.component';
import { ValiderDossierComponent } from './valider-dossier/valider-dossier.component';
import { RejeterDossierComponent } from './rejeter-dossier/rejeter-dossier.component';
import { PassportComponent } from './pages/passport/passport.component';
import { PackageComponent } from './pages/package/package.component';
import { ProduitPackageComponent } from './pages/produit-package/produit-package.component';
import { ObservationBankiComponent } from './observation-banki/observation-banki.component';
import { CommentaireListeComponent } from './commentaire-liste/commentaire-liste.component';
import { CommandeTraiteComponent } from './pages/commande-traite/commande-traite.component';
import { environment } from '../environments/environment';
import { DossierAttenteComponent } from './pages/dossier-attente/dossier-attente.component';
import { LivrerCommandeComponent } from './livrer-commande/livrer-commande.component';
import { ClientComponent } from './services/client/client.component';
import { AccessComponent } from './access/access.component';



@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,     
    FormsModule, 
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I'
    }), 
    PerfectScrollbarModule, 
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    HttpClientModule,
    SharedModule,
    NgxDatatableModule,
    PipesModule,
    AppRoutingModule,
    NgxPaginationModule,
    MatProgressSpinnerModule,
   
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    UpdatePasswordComponent,
    BlankComponent,
   ZoneComponent,
    SearchComponent,
    NotFoundComponent,
    ErrorComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    ProduitComponent,
    AddProduitComponent,
    DeleteProduitComponent,
    AllProduitComponent,
    AllCategorieComponent,
    AddCategorieComponent,
    AllQuartierComponent,
    UpdateCategorieComponent,
    DeleteCategorieComponent,
    UpdateProduitComponent,
    CommentaireListeComponent,
    ObservationBankiComponent,
    LoginComponent,
    AddZoneComponent,
    DeleteZoneComponent,
    UpdateZoneComponent,
    AddPlageComponent,
    
    DeletePlageComponent,
    AllStockComponent,
    AddStockComponent,
    UpdateStockComponent,
    DeleteStockComponent,
    AllChauffeurComponent,
    AddChauffeurComponent,
    DeleteChauffeurComponent,
    UpdateChauffeurComponent,
    TransportComponent,
    AddTransportComponent,
    DeleteTransportComponent,
    AddPaysComponent,
    UpdatePaysComponent,
    AllPaysComponent,
    UpdateCommuneComponent,
    AddCommuneComponent,
    AddRegionComponent,
    UpdateRegionComponent,
    AllRegionComponent,
    AllCommuneComponent,
    DeletePaysComponent,
    DeleteRegionComponent,
    DeleteCommuneComponent,
    AllStatutComponent,
    AddStatutComponent,
    UpdateStatutComponent,
    DeleteStatutComponent,
    UpdateComponent,
    ConfirmationUpdateComponent,
    ImageLoadedDirective,
    AllUserComponent,
    UserByTelephoneComponent,
    RechercheByUserComponent,
    AddUserComponent,
    AllRoleComponent,
    UpdateUserComponent,
    AddRoleComponent,
    DeleteRoleComponent,
    SouscriptionComponent,
    AllZoneComponent,
    ZonesComponent,
    AllZonesComponent,
    ViewPlageComponent,
    DetailsComponent,
    DetailCommandeAttenteComponent,
    CommandeAttenteComponent,
    ValiderDossierComponent,
    RejeterDossierComponent,
    PassportComponent,
    PackageComponent,
    ProduitPackageComponent,
    CommandeTraiteComponent,
    DossierAttenteComponent,
    LivrerCommandeComponent,
    ClientComponent,
    AccessComponent,
  ],
  providers: [ 
    AppSettings,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }