import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ZoneService } from '../services/zone.service';

@Component({
  selector: 'app-delete-zone',
  templateUrl: './delete-zone.component.html',
  styleUrls: ['./delete-zone.component.scss']
})
export class DeleteZoneComponent implements OnInit {

 
  constructor(public dialogRef: MatDialogRef<DeleteZoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private produitService: ProduitService,private zoneService: ZoneService, private _snackBar: SnackBarService) { }

  ngOnInit(): void {
  }
  onClickYes(){
    this.zoneService.deleteZone(this.data.id).subscribe(res => {
      this.dialogRef.close();
      this._snackBar.openSnackBar("Supression Reuissie!!", "Fermer");
    });
  }

  onClickNo(){
    this.dialogRef.close();
  }

}
