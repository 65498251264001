import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Produit } from 'src/staticData/produit';

@Component({
  selector: 'app-produit-package',
  templateUrl: './produit-package.component.html',
  styleUrls: ['./produit-package.component.scss']
})
export class ProduitPackageComponent implements OnInit {
  public produit= []
  public isReady= false
  constructor( @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.getProduitByPackageId(this.data.id)
  }


  public getProduitByPackageId(idPackage: number){
    
    for (let i = 0; i < Produit.length; i++) {
      if(Produit[i].packageId== idPackage){
        this.produit[i]= Produit[i]
      }
    }
    let c= this.produit.filter((item: any)=> item != undefined)
    this.produit= c


    this.isReady= true
  }

 
}
