import { StatutService } from './../../services/statut.service';
import { UserService } from './../../services/user.service';
import { SnackBarService } from './../../services/snack-bar.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-statut',
  templateUrl: './update-statut.component.html',
  styleUrls: ['./update-statut.component.scss']
})
export class UpdateStatutComponent implements OnInit{

  granite = true;
  statut: any;
  fileData: File = null;
  bam: boolean = false;
  etatPadding: boolean = true;
  selectedFiles?: FileList;
  selectedFileNames: string[] = [];
  progressInfos: any[] = [];
  message: string[] = [];
  
  constructor(
    private statutService: StatutService,
    private fb: FormBuilder,
    public userService:UserService, 
    private _snckBar: SnackBarService,
    public dialogRef: MatDialogRef<UpdateStatutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { 
   
    }
  statutForm =  this.fb.group({
      ordre: ['', []],
      libelle: ["", []],
      observations: ['', []],
    });
  ngOnInit(): void {
    this.getStatut();
  }

  getStatut(){
    this.statutService.getStatutById(this.data.id).subscribe(res => {
      this.statut = res;
       this.initialiseForms(this.statut[0].ordre, this.statut[0].libelle, this.statut[0].observations);
    })
  }


  



  onSubmit(){
    this.etatPadding = false;
      this.statutService.updateStatut(this.data.id, this.statutForm.value).subscribe(res => {
        this._snckBar.openSnackBar("La modification du statut!!", "Fermer");
        this.dialogRef.close();
        this.etatPadding = false;

      });
   
  }

  private initialiseForms(ordre: any, libelle: any, observations: any){
    console.log("ordre", ordre)
    this.statutForm.patchValue({
      ordre: ordre,
      libelle: libelle,
      observations: observations,
    })
  }

  
  get ordre(){
    return this.statutForm.get('ordre');
  }
  get libelle(){
    return this.statutForm.get('libelle');
  }
  get observations(){
    return this.statutForm.get('observations');
  }
}
