import {Input, Component, OnInit } from '@angular/core';
import { baseUrl } from '../backend';
import { CommentaireService } from '../services/commentaire.service';

@Component({
  selector: 'app-commentaire-liste',
  templateUrl: './commentaire-liste.component.html',
  styleUrls: ['./commentaire-liste.component.scss']
})
export class CommentaireListeComponent implements OnInit {
  @Input() commentaire: any
  public ap = `${baseUrl}zones`;
  constructor() { }

  ngOnInit(): void {
  }
}
