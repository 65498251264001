import { UserService } from '../services/user.service';
import { AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function emailOrTelValidators(userServive: UserService, route): AsyncValidatorFn{
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return userServive.emailOrTelExist(control.value).pipe(
       map(user => {
           if(user.length > 0){
               if(true){
                   return null;
               }else{
               }
           }else{
               return {'emailExist': true}
           }    
        })
    )
  }
}