import { PaysService } from 'src/app/services/pays.service';
import { RegionService } from './../../services/region.service';
import { SnackBarService } from './../../services/snack-bar.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-region',
  templateUrl: './update-region.component.html',
  styleUrls: ['./update-region.component.scss']
})
export class UpdateRegionComponent {
  region: any;
  public pays: any;

  etatPadding: boolean = true;
    
    constructor(private paysService: PaysService,private fb: FormBuilder, private regionService: RegionService, private _snckBar: SnackBarService,public dialogRef: MatDialogRef<UpdateRegionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.getAllPays()
    this.getRegionById();
    
  }
  public getAllPays(){
    this.paysService.getAllPays().subscribe(res => {
      this.pays = res;
    });
  }
  getRegionById(){
    this.regionService.getRegionById(this.data.id).subscribe(res => {
      this.region = res;
      this.initialiseForms();
    })
  }

 

  regionForm =  this.fb.group({
    pays_id:['',  {
      validators: [
      Validators.required,
    ]},],
    libelle: ['',  {
      validators: [
      Validators.required,
    ]}],
  });


 
  onSubmit(){
    this.etatPadding = false;
        this.regionService.updateRegion(this.data.id, this.regionForm.value).subscribe(res => {
          this._snckBar.openSnackBar("Modification Reuissie!!", "Fermer");
          this.dialogRef.close();
          this.etatPadding = false;
        })
   
  }

  private initialiseForms(){
    this.regionForm.patchValue({
      libelle: this.region[0].libelle ? this.region[0].libelle : '',
      pays_id: this.region[0].pays_id ? this.region[0].pays_id : '',
    })
  }

  get pays_id(){
    return this.regionForm.get('pays_id');
  }
   get libelle(){
     return this.regionForm.get('libelle');
   }
}
