import { UserService } from 'src/app/services/user.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ZoneService } from '../services/zone.service';
import { UpdateZoneComponent } from '../update-zone/update-zone.component';

@Component({
  selector: 'app-update-chauffeur',
  templateUrl: './update-chauffeur.component.html',
  styleUrls: ['./update-chauffeur.component.scss']
})
export class UpdateChauffeurComponent implements OnInit {

  zones: any[] = [];

  produit: any[] = [];
  zone: any;
 
  categories: any[] = [];
  etatPadding: boolean = true;

  constructor(private fb: FormBuilder,private userService: UserService, private zoneServices: ZoneService, private produitService: ProduitService, private _snckBar: SnackBarService,public dialogRef: MatDialogRef<UpdateZoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.getProduit();
    this.getZones();
  }
  
  getZones(){
    this.zoneServices.getChauffeur(this.data.id).subscribe(res => {
      this.zone = res;
      this.initialiseForms();
    })
  }
  getProduit(){
    this.produitService.getProduits(this.userService.getUserDetails()._id).subscribe(res => {
      this.produit = res;
    })
  }

  

  zoneForm = this.fb.group({

    
    nom: ['', []],
    prenom: ['', []],
    telephone: ['', []],
    ville: ['', []],
    commune: ['', []],
    
    
    
  });

  

  onSubmit(){
    this.zoneServices.updateChauffeur(this.data.id,this.zoneForm.value).subscribe(res => {
        this._snckBar.openSnackBar("Modification Reuissie!!", "Fermer");
        this.dialogRef.close();
        this.etatPadding = false;

      });
    
  }

  private initialiseForms(){
    this.zoneForm.patchValue({
      nom: this.zone[0].nom ? this.zone[0].nom : '',
      prenom: this.zone[0].prenom ? this.zone[0].prenom : '',
      telephone: this.zone[0].telephone ? this.zone[0].telephone : '',
      ville: this.zone[0].ville ? this.zone[0].ville: '',
      commune: this.zone[0].commune ? this.zone[0].commune : '',
    })
  }


 
  
  
  get nom(){
    return this.zoneForm.get('nom');
  }
  get prenom(){
    return this.zoneForm.get('prenom');
  }
  get telephone(){
    return this.zoneForm.get('telphone');
  }
  get ville(){
    return this.zoneForm.get('ville');
  }
  get commune(){
    return this.zoneForm.get('commune');
  }
  
 


}
