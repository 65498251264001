import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
//import { userService } from 'src/app/services/zone.service';
import { UserService } from 'src/app/services/user.service';
import { telValidatorRegister } from 'src/app/validators/telephone-validator-register';
import { updateTelUserValidator } from 'src/app/validators/update-tel-user.validators';
import { updateEmailUserValidator } from 'src/app/validators/update-email-user.validators';
import { ProduitService } from 'src/app/services/produit.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {

 
  zones: any[] = [];

  produit: any[] = [];
  user: any;
 livraisons = 0;
  categories: any[] = [];
  etatPadding: boolean = true;

  constructor(private fb: FormBuilder, private produitService: ProduitService,private userService: UserService, private _snckBar: SnackBarService,public dialogRef: MatDialogRef<UpdateUserComponent>,
    @Inject(MAT_DIALOG_DATA) public   data: any,) {
     }

  ngOnInit(): void {
    this.getUserById();
   
  }
  
  getUserById(){
    this.userService.getUserById(this.data.id).subscribe(res => {
      this.user = res;
      
      this.initialiseForms();
    })
  }
  userForm = this.fb.group({
    name: ['', [Validators.required]],
    tel: ['', {
      validators: [
      Validators.required,
        Validators.minLength(9),
        Validators.pattern(/^[0-9+]{9,}$/), 
    ],
      asyncValidators: [updateTelUserValidator(this.userService, this.data.id)]
    }
    ],
    email: ['', {
      validators: [
       Validators.required,
        Validators.email,
     ],
      asyncValidators: [updateEmailUserValidator(this.userService, this.data.id)]
    }
   ],
  
   zone: ['', []],
   commune: ['', []],
   societe: ['', []],
   ville: ['', []],
   role: ['Fournisseur'],

  });

  getEmailError() {
    if (this.email.invalid && (this.email.dirty || this.email.touched)) {
      if (this.email.errors.required) {
        return 'Le email est requis!';
      }else if(this.email.errors.emailExist){
        return 'Ce email existe dejat!';
      }else if(this.email.errors.email){
        return 'Email incorect!!';
      }
    }
  }

  onSubmit(){
    this.etatPadding = false;
    this.userService.updateUsers(this.data.id,this.userForm.value).subscribe(res => {
        this._snckBar.openSnackBar("Modification Reuissie!!", "Fermer");
        this.dialogRef.close();
        this.etatPadding = true;

      });
    
  }

  private initialiseForms(){
    this.userForm.patchValue({
      name: this.user.name ? this.user.name : '',
      email: this.user.email? this.user.email : '',
      tel: this.user.tel ? this.user.tel : '',
      commune: this.user.commune ? this.user.commune : '',
     ville: this.user.ville ? this.user.ville : '',
      zone: this.user.zone ? this.user.zone : '',
      societe: this.user.societe ? this.user.societe : '',


    })
  }


 
  getTelError() {
    if (this.tel.invalid && (this.tel.dirty || this.tel.touched)) {
      if (this.tel.errors.required) {
        return 'Le nom est requis!';
      }else if(this.tel.errors.telExist){
        return 'Ce numero de telephone existe dejat!';
      }else if(this.tel.errors.pattern){
        return 'Numero telephone incorect!!';
      }
    }
  }

  getTelSuccess() {
    if (this.tel.valid) {
      return true;
    }
  }

  
  getEmailSuccess() {
    if (this.email.valid) {
      return true;
    }
  }
  selectZone(event: any){
    this.livraisons = event.target.value;
   
  }
  get name(){
    return this.userForm.get('name');
  }
  
  get societe(){
    return this.userForm.get('societe');
  }
  

  get email(){
    return this.userForm.get('email');
  }
  get tel(){
    return this.userForm.get('tel');
  }
 
  get commune(){
    return this.userForm.get('commune');
  }
  get ville(){
    return this.userForm.get('ville');
  }
 
  get zone(){
    return this.userForm.get('zone');
  }


}
