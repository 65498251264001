import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../backend';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class StatutService {

  ap = `${baseUrl}zones`;
  
  constructor(private http: HttpClient) { }

  public addStatut(statut: any): Observable<any>{
    return this.http.post<any>(`${this.ap}/addStatut`, statut).pipe(
      retry(3)
    );
  }
  public getAllStatut(): Observable<any>{
    return this.http.get<any>(`${this.ap}/getAllStatut`).pipe(
      retry(3)
    );
  }
  public updateStatut(id: any, statut): Observable<any>{
    return this.http.put<any>(`${this.ap}/updateStatut/${id}`, statut).pipe(
      retry(3)
    );
  }
  

  public deleteStatut(id: any): Observable<any>{
    return this.http.delete<any>(`${this.ap}/deleteStatut/${id}`).pipe(
      retry(3)
    );
  }

  public getStatutById(id: any): Observable<any>{
    return this.http.get<any>(`${this.ap}/getStatutById/${id}`).pipe(
      retry(3)
    );
  }
}
