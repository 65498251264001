import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
 
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  hide:boolean = true;
  options: UntypedFormGroup;
  themingForm: UntypedFormGroup;
  public settings: Settings;
  etatPadding: boolean = true;
  subscription: Subscription;
  user: any;
  userPrint: any;
  constructor(private router: Router,private _snackBar: SnackBarService,private fb: FormBuilder,private userService:UserService,public appSettings:AppSettings, public formBuilder:UntypedFormBuilder) {
    this.settings = this.appSettings.settings;     
  }

  ngOnInit(){
    this.options = this.formBuilder.group({
      hideRequired: false,
      floatLabel: 'auto',
    });
    this.themingForm = this.formBuilder.group({
      'color': 'primary',
      'fontSize': [16, Validators.min(10)],
    });
    this.getUser();
    this.getUserPrint();
  }
  
  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
        this.email.hasError('email') ? 'Not a valid email' :
            '';
  }

  getFontSize() {
    return Math.max(10, this.themingForm.value.fontSize);
  }
  registerForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(6)]]
  });
  onSubmit(){
    this.etatPadding = false;
    this.userService.validation(this.userService.getUserDetails()._id,this.registerForm.value).subscribe(res => {
      this._snackBar.openSnackBar("Modification Reuissie!!", "FERMER");
      this.router.navigate(['/']);
      this.etatPadding = true;
    });
  }
  getUser(){
    this.userService.profile().subscribe(res => {
      this.user = res;
      //this.initialiseForms();
    })
  }

  getUserPrint(){
    this.userService.profile().subscribe(res => {
      this.userPrint = res;
    })
  }
  
  getPasswordError() {
    if (this.password.invalid && (this.password.dirty || this.password.touched)) {
      if (this.password.errors.required) {
        return 'Le mot de passe est requis!';
      }else if(this.password.errors.minlength){
        return 'Mot de passe trop pettit!';
      }
    }
  }

  getPasswordSuccess() {
    if (this.password.valid) {
      return true;
    }
  }
  get password(){
    return this.registerForm.get('password');
  }
}
