import { StatutService } from './../../services/statut.service';
import { UserService } from './../../services/user.service';
import { SnackBarService } from './../../services/snack-bar.service';
import { ProduitService } from './../../services/produit.service';
import { Settings } from './../../app.settings.model';
import { AppSettings } from './../../app.settings';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroupDirective, NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-statut',
  templateUrl: './add-statut.component.html',
  styleUrls: ['./add-statut.component.scss']
})
export class AddStatutComponent {
  selectedFiles?: FileList;
  selectedFileNames: string[] = [];

  progressInfos: any[] = [];
  message: string[] = [];

  previews: string[] = [];
  imageInfos?: Observable<any>;
  settings: Settings;
  granite = true;
  fileData: File = null;
  bam: boolean = false;
  etatPadding: boolean = true;
  constructor(
    private _snckBar: SnackBarService,
    private statutService: StatutService,
    public dialogRef: MatDialogRef<AddStatutComponent>,
    public userService:UserService,
    public appSettings:AppSettings,
    private fb: FormBuilder,
    private produitService: ProduitService) {
    this.settings = this.appSettings.settings;     
  }
  ngOnInit(): void {
   
  }
  statutForm =  this.fb.group({
    ordre: ['',  {
      validators: [
      Validators.required,
    ]},],
    libelle: ['',  {
      validators: [
      Validators.required,
    ]},],  
    observations: [''],
   
   
  });
  onSubmit(){
    this.etatPadding = false;
    this.statutService.addStatut(this.statutForm.value).subscribe(res => {
      this._snckBar.openSnackBar("statut Ajouté!!", "Fermer");
      this.dialogRef.close();
      this.etatPadding = true;
    })
    // const formData = new FormData();
    // formData.append('file', this.image.value);
    // if(this.bam){
    //   this.produitService.upload(formData).subscribe(res => {
    //     this.image.setValue(res.path);
    //     this.distributeur_id.setValue(this.userService.getUserDetails()._id);
    //     this.produitService.addStatut(this.statutForm.value).subscribe(res => {
         
    //       console.log("groupe",this.statutForm.value);
    //       this.dialogRef.close();
    //       this._snckBar.openSnackBar("statut Ajouter!!", "Fermer");
    //       this.etatPadding = true;

    //     });
    //   })
    // }else{
    //   this.distributeur_id.setValue(this.userService.getUserDetails()._id);

    //   this.produitService.addStatut(this.statutForm.value).subscribe(res => {
    //     this._snckBar.openSnackBar("statut Ajoutée!!", "Fermer");
    //     this.dialogRef.close();
    //     this.etatPadding = true;

    //   });
    // }
    
  }
  close(): void {
    this.dialogRef.close();
  }
  get ordre(){
    return this.statutForm.get('ordre');
  }
  get libelle(){
    return this.statutForm.get('libelle');
  }
  get observations(){
    return this.statutForm.get('observations');
  }
  //Input with a custom ErrorStateMatcher

 

  //Input with a clear button

 

}
