import { UserService } from 'src/app/services/user.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProduitService } from '../services/produit.service';
import { RegionService } from '../services/region.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ZoneService } from '../services/zone.service';
import { CommuneService } from '../services/commune.service';

@Component({
  selector: 'app-add-zone',
  templateUrl: './add-zone.component.html',
  styleUrls: ['./add-zone.component.scss']
})
export class AddZoneComponent implements OnInit {

  etatPadding: boolean = true;
  produits: any[] = [];
  communeById: any;
  regions: any[] = [];
  verifie_region: any[] = [];
  verifie_id = 0;
  status_region = 0;
  constructor(
    public dialogRef: MatDialogRef<AddZoneComponent>,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,private communeService: CommuneService,private regionService:RegionService,private zoneService:ZoneService,private fb: FormBuilder, private _snackBar: SnackBarService) { }

  zoneForm = this.fb.group({
   
   
    commune_id: ['', []],
    localite: ['', [Validators.required]]
   
  });

  ngOnInit(): void {
   this.getRegion();
   this.getCommuneById();
  }
  getRegion(){
    this.regionService.getAllRegion().subscribe(res => {
      this.regions = res;
    });
   }
   getCommuneById(){
    this.communeService.getCommuneById(this.data.id).subscribe(res => {
      this.communeById = res;
     console.log("zone bella",this.communeById[0]._id);

    })
  }
  selectRegion(event: any){
   
   
    this.status_region = event.target.value;
    this.verifie_id = event.target.value;
   
    this.zoneService.getAllCommuneByRegion(this.verifie_id).subscribe(res => {
      this.verifie_region= res;
    console.log("regions bella",this.verifie_region);
   
    })
      
       // this.zoneCalcule = this.lieux[0].produit_id?.prix;    
  
  }



  onSubmit(){
    this.etatPadding = false;
    this.commune_id.setValue(this.communeById[0]._id);

    this.zoneService.addZone(this.zoneForm.value).subscribe(res => {
      this._snackBar.openSnackBar("Zone Ajouter!!", "Fermer");
     
      this.etatPadding = true;
      
      this.dialogRef.close();
    })
   
  }
  get commune_id(){
    return this.zoneForm.get('commune_id');
  }
}
