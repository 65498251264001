import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommandeService } from '../services/commande.service';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { UserService } from '../services/user.service';
import { ZoneService } from '../services/zone.service';
import { UpdateZoneComponent } from '../update-zone/update-zone.component';

@Component({
  selector: 'app-update-stock',
  templateUrl: './update-stock.component.html',
  styleUrls: ['./update-stock.component.scss']
})
export class UpdateStockComponent implements OnInit {

  zones: any[] = [];

  produit: any[] = [];
  users: any[] = [];
  zone: any;
 
  categories: any[] = [];
  etatPadding: boolean = true;

  constructor(private fb: FormBuilder,private userService: UserService, private zoneServices: ZoneService,private commandeServices: CommandeService, private produitService: ProduitService,private distrubuteur: UserService, private _snckBar: SnackBarService,public dialogRef: MatDialogRef<UpdateZoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.getProduit();
    this.getZones();
    this.distributeurs()
  }
  
  getZones(){
    this.commandeServices.StockById(this.data.id).subscribe(res => {
      this.zone = res;
      this.initialiseForms();
      
    })
  }
  getProduit(){
    this.produitService.getProduits(this.userService.getUserDetails()._id).subscribe(res => {
      this.produit = res;
    })
  }
  
  zoneForm = this.fb.group({
    fournisseur_id: ['', []],
    produit_id: ['', []],
    quantite: ['', []],
    prix: ['', []],
   
  });

  

  onSubmit(){
    this.commandeServices.updateStock(this.data.id,this.zoneForm.value).subscribe(res => {
        this._snckBar.openSnackBar("Modification Reuissie!!", "Fermer");
        this.dialogRef.close();
        this.etatPadding = false;
      });
  }

  private initialiseForms(){
    
    this.zoneForm.patchValue({
      quantite: this.zone[0].quantite ? this.zone[0].quantite : '',
      prix: this.zone[0].prix ? this.zone[0].prix : '',
      produit_id: this.zone[0].produit_id ? this.zone[0].produit_id : '',
      fournisseur_id: this.zone[0].fournisseur_id ? this.zone[0].fournisseur_id : '',
    })
  }
  
  
  distributeurs(){
   this.distrubuteur.allUsersF().subscribe(res=>{
       this.users=res;
     })
 }

  get fournisseur_id(){
    return this.zoneForm.get('fournisseur_id');
  }
  get quantite(){
    return this.zoneForm.get('quantite');
  }

  get prix(){
    return this.zoneForm.get('prix');
  }
  

  
 

}
