import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';

import { UserService } from 'src/app/services/user.service';
import { emailOrTelValidators } from 'src/app/validators/email-or-telephone.validator';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';
import { AddUserComponent } from '../add-user/add-user.component';
import { AllRoleComponent } from '../all-role/all-role.component';
import { UpdateUserComponent } from '../update-user/update-user.component';
import { UserDialogComponent } from '../users/user-dialog/user-dialog.component';
import { User } from '../users/user.model';

@Component({
  selector: 'app-user-by-telephone',
  templateUrl: './user-by-telephone.component.html',
  styleUrls: ['./user-by-telephone.component.scss']
})
export class UserByTelephoneComponent implements OnInit {

  public user: User[];
// users: any[] = []; 
 users: any[]; 
tutorials: User[] = [];
//currentTutorial: User = {};
currentIndex = -1;
title = '';
subscription: Subscription;
page = 1;
count = 0;
pageSize = 3;
pageSizes = [3, 6, 9];

 public searchText: string;
 
 public settings: Settings;
 constructor(public appSettings:AppSettings,private router: Router,private route: ActivatedRoute,
             public dialog: MatDialog,private fb: FormBuilder,
             private userService:UserService){
     this.settings = this.appSettings.settings; 
 }
 registerForm = this.fb.group({
    
  email: ['', {
    validators: [
     Validators.required,
   ],
    asyncValidators: [emailOrTelValidators(this.userService, 'login')]}
 ]
});

onSubmit(){
 

  this.userService.recherche(this.registerForm.value).subscribe(res => {
    console.log("value",res[0]._id)
 this.router.navigate(["/userByTelephone",res[0]._id]);


  });

  

  


}
 ngOnInit() {
  this.subscription =  timer(0, 3000).subscribe(res => {
      this.getUsers(); 
    });
   //  this.retrieveTutorials();        
 }
 getEmailError() {
  if (this.email.invalid && (this.email.dirty || this.email.touched)) {
    if (this.email.errors.required) {
      return 'Cet champs est requis!';
    }else if(this.email.errors.emailExist){
      return 'Cet telephone ou email n\'existe pas!';
    }else if(this.email.errors.email){
      return 'Email incorect!!';
    }
  }
}

getEmailSuccess() {
  if (this.email.valid) {
    return true;
  }

}

get email(){
  return this.registerForm.get('email');
}
 getRequestParams(searchTitle: string, page: number, pageSize: number): any {
  let params: any = {};

  if (searchTitle) {
    params[`title`] = searchTitle;
  }

  if (page) {
    params[`page`] = page - 1;
  }

  if (pageSize) {
    params[`size`] = pageSize;
  }

  return params;
}

retrieveTutorials(): void {
  const params = this.getRequestParams(this.title, this.page, this.pageSize);
    this.userService.allUsersD(params.page).subscribe((res: any)=>{
      this.tutorials = res.docs;
      this.count = res.totalDocs;
      this.page = params.page;
    })
}

handlePageChange(event: number): void {
  this.page = event;
  this.userService.allUsersD(this.page).subscribe((res: any)=>{
    this.tutorials = res.docs;
    this.count = res.totalDocs
  })
}

handlePageSizeChange(event: any): void {
  this.pageSize = event.target.value;
  this.page = 1;
  this.retrieveTutorials();
}



searchTitle(): void {
  this.page = 1;
  this.retrieveTutorials();
}

 getUsers() {
     this.user = null; //for show spinner each time
           const id = this.route.snapshot.paramMap.get('id');

     this.userService.RechercheUser(id).subscribe(res=>{
      this.tutorials = res;
           console.log("User bella", this.user);
         })
 
 }
 public addUser(user:User){
   //  this.usersService.addUser(user).subscribe(user => this.getUsers());
 }
 public updateUser(user:User){
    // this.usersService.updateUser(user).subscribe(user => this.getUsers());
 }
 public deleteUser(user:User){
   // this.usersService.deletesUser(user.id).subscribe(user => this.getUsers());
 }


 public onPageChanged(event){
     this.page = event;
     this.getUsers();
     if(this.settings.fixedHeader){      
         document.getElementById('main-content').scrollTop = 0;
     }
     else{
         document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
     }
 }
 
 public openRoleDialog(id){
  let dialogRef = this.dialog.open(AllRoleComponent, {
    width: '300px',
    height: '300px',
    data: {id: id}
  })};
 public openUserDialog(user){
     let dialogRef = this.dialog.open(AddUserComponent, {
         data: user
     });

     dialogRef.afterClosed().subscribe(user => {
         if(user){
             (user.id) ? this.updateUser(user) : this.addUser(user);
         }
     });
 }

 public openUpdateUser(id: any){
  this.dialog.open(UpdateUserComponent, {
    data: {id: id}
  });


}

}
