import { UpdateStatutComponent } from './../update-statut/update-statut.component';
import { DeleteStatutComponent } from './../delete-statut/delete-statut.component';
import { AddStatutComponent } from './../add-statut/add-statut.component';
import { MatDialog } from '@angular/material/dialog';
import { StatutService } from './../../services/statut.service';
import { Settings } from 'src/app/app.settings.model';
import { Component, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AppSettings } from 'src/app/app.settings';

@Component({
  selector: 'app-all-statut',
  templateUrl: './all-statut.component.html',
  styleUrls: ['./all-statut.component.scss']
})
export class AllStatutComponent {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  editing = {};
  rows = [];
  temp = [];
  public region
  selected = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  columns = [
    { prop: 'name' },
    { name: 'libelle'},
    { name: 'ordre'},
    { name: 'actions' },
  ];
  public settings: Settings;

  constructor(
    private dialog: MatDialog,
    private statutService: StatutService,
    public appSettings:AppSettings,
    ) {
    this.settings = this.appSettings.settings; 
    this.statutService.getAllStatut()
      .subscribe((data)=>{
        this.temp= [...data];
        this.rows = data;
        setTimeout(() => { this.loadingIndicator = false; }, 1500);
      })
  }

  fetch(data) {
    const req = new XMLHttpRequest();
    req.open('GET', 'assets/data/company.json');
    req.onload = () => {
      data(JSON.parse(req.response));
    };
    req.send();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function(d) {
      console.log(temp)
      return d.libelle.toLowerCase().indexOf(val) !== -1 || d.pays_id.libelle.toLowerCase().indexOf(val) !== -1 ||  !val;
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  updateValue(event, cell, rowIndex) {
    this.editing[rowIndex + '-' + cell] = false;
    this.rows[rowIndex][cell] = event.target.value;
    this.rows = [...this.rows];
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

addStatut(){
  this.dialog.open(AddStatutComponent, {
    width: '350px',
    height: '300px',
  })
}
deleteStatut(id){
  this.dialog.open(DeleteStatutComponent, {
    data: {id: id}
  })
}
updateStatut(id){
  this.dialog.open(UpdateStatutComponent, {
    data: {id: id}
   
  })
  }
  onActivate(event) {
  }

}
