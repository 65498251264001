import { baseUrl } from './../backend';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  constructor() { }

  printPicture(avatar){
    if(avatar){
      return `${baseUrl}${avatar}`;
    }else{
      return 'assets/user/img/avatar/avatar.png';
    }
  }

  printImage(image){
    if(image){
      return `${baseUrl}${image}`;
    }else{
      return '../assets/images/logo/logo3.jpeg';
    }
  }
  
  printlogo(logo){
    if(logo){
      return `${baseUrl}${logo}`;
    }else{
      return '../assets/images/logo/logo3.jpeg';
    }
  }
}
