import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormBuilder,
} from "@angular/forms";

import { UserService } from "src/app/services/user.service";
import { RoleService } from "src/app/services/role.service";
import { Settings } from "../app.settings.model";
import { emailValidator } from "../theme/utils/app-validators";
import { AppSettings } from "../app.settings";
import { MatDialog } from "@angular/material/dialog";
import { UpdateComponent } from "../update/update.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent {
  passwordIncorect: boolean = true;
  etatPadding: boolean = true;
  verfy: boolean = false;
  is_true: boolean = false;

  _id: any;
  quantite: any;
  zone: any;
  livraison: any;
  public form: UntypedFormGroup;
  public settings: Settings;
  constructor(
    private dialog: MatDialog,
    public RoleService: RoleService,
    private userService: UserService,
    public router: Router,
    public route: ActivatedRoute,
    public appSettings: AppSettings,
    public fb: UntypedFormBuilder
  ) {
    this.settings = this.appSettings.settings;
    this.form = this.fb.group({
      email: [null, Validators.compose([Validators.required, emailValidator])],
      password: [
        null,
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
    });
  }

  ngAfterViewInit() {
    this.settings.loadingSpinner = false;
  }

  ngOnInit(): void {}

  loginForm = this.fb.group({
    email: [
      "",
      {
        validators: [Validators.required],
      },
    ],
    password: ["", [Validators.required]],
  });

  public onSubmit() {
    this.etatPadding = false;
    this.userService.login(this.loginForm.value).subscribe((res) => {
      if (!res) {
        this.passwordIncorect = false;
      } else {
        let element = this.chercherSiRoleUtilisateurExiste(
          this.userService.getUserDetails().roles,
          "Livraison"
        );
        if (element) {
          this.verfy = false;
          if (this.RoleService.getRoleValide()) {
            this.router.navigate(["/update_password"]);
          } else {
            this.router.navigate([""]);
          }
        } else {
          window.localStorage.clear();
          this.verfy = true;
          this.etatPadding = true;
        }
      }
    });
  }

  chercherSiRoleUtilisateurExiste(tab: any[], role: string): Boolean {
    for (let i = 0; i < tab.length; i++) {
      if (tab[i].role == role) {
        return true;
      }
    }
  }

  getEmailError() {
    if (this.email.invalid && (this.email.dirty || this.email.touched)) {
      if (this.email.errors.required) {
        return "Cet champs est requis!";
      } else if (this.email.errors.emailExist) {
        return "Cet telephone ou email n'existe pas!";
      } else if (this.email.errors.email) {
        return "Email incorect!!";
      }
    }
  }

  getEmailSuccess() {
    if (this.email.valid) {
      return true;
    }
  }

  get email() {
    return this.loginForm.get("email");
  }

  get password() {
    return this.loginForm.get("password");
  }

  onReset() {
    this.dialog.open(UpdateComponent, {
      width: "250px",
      height: "100px",
    });
  }
}
