import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-update-produit',
  templateUrl: './update-produit.component.html',
  styleUrls: ['./update-produit.component.scss']
})
export class UpdateProduitComponent implements OnInit {

  granite = true;
  produit: any;
  fileData: File = null;
  bam: boolean = false;
  categories: any[] = [];
  etatPadding: boolean = true;
  selectedFiles?: FileList;
  selectedFileNames: string[] = [];

  progressInfos: any[] = [];
 
  message: string[] = [];

  previews: string[] = [];
  imageInfos?: Observable<any>;
 
 
  constructor(public userService:UserService,private fb: FormBuilder, private produitService: ProduitService, private _snckBar: SnackBarService,public dialogRef: MatDialogRef<UpdateProduitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.getProduit();
    this.getCategories();
  }
  getCategories(){
    this.produitService.getCategorie().subscribe(res => {
      this.categories = res;
      console.log("mes categories",this.categories);
    });
  }
  getProduit(){
    this.produitService.getProduit(this.data.id).subscribe(res => {
      this.produit = res;
  
      console.log('I AM A RESULT ', this.produit);
      
      this.initialiseForms();
    })
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    if (fileInput.target.files.length > 0) {
      const file = fileInput.target.files[0];
      this.produitForm.get('image').setValue(file);
      this.bam = true;
      console.log('JE SUIS LE FILE', file);
    }
  }

  produitForm =  this.fb.group({
    unite: ['', [Validators.required]],
    societe: ['', [Validators.required]],
    categorie_id: ['', [Validators.required]],
    pays: ['', [Validators.required]],
    devise: ['', [Validators.required]],
    type: ['', []],
    flash: ['', []],
    zone: ['', []],
    ville: ['', []],
    promo: ['', []],
    prix: ['', [Validators.required, Validators.pattern(/^[0-9+]{1,}$/)]],
    description: [''],
    distributeur_id: [''],
    image: [''],
    status: [1],
  });

  select(event){
    if(event.target.value == 'GRANITE' ||event.target.value == 'BLOC' ||event.target.value == 'CIMENT'||event.target.value == 'GRAVIER' ||event.target.value == 'FER A BETON' ||event.target.value == 'SABLE'||event.target.value == 'LATERITE' ){
      this.granite = true;
    }else{
      this.granite = false;
    }
  }

  onSubmit(){
    this.etatPadding = false;

    const formData = new FormData();
    formData.append('file', this.image.value);
    console.log('FORM DATA', this.image);
    
    if(this.bam){
      this.produitService.upload(formData).subscribe(res => {
        this.image.setValue(res.path);
        this.distributeur_id.setValue(this.userService.getUserDetails()._id);

        this.produitService.updateProduit(this.data.id, this.produitForm.value).subscribe(res => {
          this._snckBar.openSnackBar("Modification Reuissie!!", "Fermer");
          this.dialogRef.close();
          this.etatPadding = false;

        });
      });
    }else{
      this.image.setValue(this.produit.image);
      this.distributeur_id.setValue(this.userService.getUserDetails()._id);
      this.produitService.updateProduit(this.data.id, this.produitForm.value).subscribe(res => {
        this._snckBar.openSnackBar("Modification Reuissie!!", "Fermer");
        this.dialogRef.close();
        this.etatPadding = false;

      });
    }
  }

  private initialiseForms(){
    this.produitForm.patchValue({
      unite: this.produit.unite ? this.produit.unite : '',
      societe: this.produit.societe ? this.produit.societe : '',
      //categorie_id: this.produit.marque ? this.produit.marque : '',
      pays: this.produit.pays ? this.produit.pays : '',
      devise: this.produit.devise ? this.produit.devise : '',
      type: this.produit.type ? this.produit.type : '',
      prix: this.produit.prix ? this.produit.prix : '',
      flash: this.produit.flash ? this.produit.flash : '',
      promo: this.produit.promo ? this.produit.promo : '',

      image: this.produit.image ? this.produit.image : '',

      description: this.produit.description ? this.produit.description : '',
    })
  }

  getPrixError(): any {
    if (this.prix?.invalid && (this.prix?.dirty || this.prix?.touched)) {
      if (this.prix.errors?.required) {
        return 'Le Prix est requis!!';
      }else if(this.prix.errors?.pattern){
        return 'Le Prix doit etre un entier!!';
      }
    }
  }

  getPrixSuccess(): any {
    if (this.prix?.valid) {
      return true;
    }
  }
  get distributeur_id(){
    return this.produitForm.get('distributeur_id');
  }
  get prix(){
    return this.produitForm.get('prix');
  }
  get flash(){
    return this.produitForm.get('flash');
  }
  get promo(){
    return this.produitForm.get('promo');
  }
  get categorie_id(){
    return this.produitForm.get('categorie_id');
  }
  get devise(){
    return this.produitForm.get('devise');
  }
  get description(){
    return this.produitForm.get('description');
  }
  get societe(){
    return this.produitForm.get('societe');
  }
  get unite(){
    return this.produitForm.get('unite');
  }
  get pays(){
    return this.produitForm.get('pays');
  }
  get image(){
    return this.produitForm.get('image');
  }

}
