import { Settings } from './../../app.settings.model';
import { AppSettings } from './../../app.settings';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, timer } from 'rxjs';
import { AddZoneComponent } from 'src/app/add-zone/add-zone.component';
import { CommandeService } from 'src/app/services/commande.service';
import { ZoneService } from 'src/app/services/zone.service';
import { ViewPlageComponent } from 'src/app/view-plage/view-plage.component';
import { Passport } from 'src/staticData/passport';
import { PackageComponent } from '../package/package.component';
import { Package } from 'src/staticData/package';

@Component({
  selector: 'app-passport',
  templateUrl: './passport.component.html',
  styleUrls: ['./passport.component.scss']
})
export class PassportComponent implements OnInit {
  page = 1;
  count = 0;
  pageSize = 3;
  public isReady= false
  title = '';
  public numberOfTimer=0
  public passport=[]
  public settings: Settings;

  subscription: Subscription;
  public stock: []=[]
  pageSizes = [3, 6, 9];

  displayedColumns: string[] = ['fournisseur', 'produit', 'quantite','action','a'];
    constructor(private userService: UserService,private zoneService:ZoneService,private stockService:CommandeService,private dialog: MatDialog, public appSettings:AppSettings){
      this.settings = this.appSettings.settings; 

    }
 


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngAfterViewInit() {
  }

  ngOnInit(): void {
    this.getPassport()
  
  
  }
 

    getPassport(){
        this.passport  = Passport
        this.isReady= true
      }

    viewPackage(idPassport: any){
      this.dialog.open(PackageComponent, {
        data: {id: idPassport},
        width: '400px',
        height: '380px',
       
      })
    }
    
 

}

