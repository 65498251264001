import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ZoneService } from '../services/zone.service';

@Component({
  selector: 'app-delete-plage',
  templateUrl: './delete-plage.component.html',
  styleUrls: ['./delete-plage.component.scss']
})
export class DeletePlageComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeletePlageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private produitService: ProduitService,private zoneService: ZoneService, private _snackBar: SnackBarService) { }

  ngOnInit(): void {
  }
  onClickYes(){
    this.zoneService.deletePlage(this.data.id,this.data.id_zone).subscribe(res => {
     // this.router.navigate(['/boutiques/shopingByCategorie']);
      this._snackBar.openSnackBar("Commande Annuler!!", "Quitter");
      this.dialogRef.close();
    })
  }

  onClickNo(){
    this.dialogRef.close();
  }

}
