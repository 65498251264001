import { UserService } from 'src/app/services/user.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddProduitComponent } from 'src/app/add-produit/add-produit.component';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { DeleteProduitComponent } from 'src/app/delete-produit/delete-produit.component';
import { PrintService } from 'src/app/services/print.service';
import { ProduitService } from 'src/app/services/produit.service';
import { UpdateProduitComponent } from 'src/app/update-produit/update-produit.component';
import { Produit } from './Produit.model';

@Component({
  selector: 'app-all-produit',
  templateUrl: './all-produit.component.html',
  styleUrls: ['./all-produit.component.scss']
})
export class AllProduitComponent implements OnInit {
  produitAjouter= [
    {id: "", prix: ""}
  ]
  currentIndex = -1;
title = '';
public isReady= false
page = 1;
count = 0;
pageSize = 3;
pageSizes = [3, 6, 9];
public searchText: string;

 // produits: any[] = []; 
  public produits: Produit[]
  public prod: Produit[]= []
   public settings: Settings;
   public dataReady= false
  constructor(
    public print: PrintService,
    public userService: UserService,
    private dialog: MatDialog,
    public appSettings:AppSettings,
    private produitService:ProduitService
    ) {
    this.settings = this.appSettings.settings; 
  }

  ngOnInit(): void {
    this.getPaginatedProduits();
  }

  getPaginatedProduits(){
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    this.produitService.getPaginatedProduits(this.userService.getUserDetails()._id, this.page).subscribe((res: any) => {
      this.produits = res.docs;
      this.count = res.totalDocs;
      this.page = params.page;
      this.isReady= true
    });
}

getRequestParams(searchTitle: string, page: number, pageSize: number): any {
  let params: any = {};

  if (searchTitle) {
    params[`title`] = searchTitle;
  }

  if (page) {
    params[`page`] = page - 1;
  }

  if (pageSize) {
    params[`size`] = pageSize;
  }

  return params;
}

retrieveTutorials(): void {
  const params = this.getRequestParams(this.title, this.page, this.pageSize);
  this.produitService.getPaginatedProduits(this.userService.getUserDetails()._id,this.page).subscribe((res: any) => {
    this.produits = res.doc;
    this.count = res.totalDocs;
    this.page = params.page;
  });
}


public onPageChanged(event){
  this.page = event;
  this.getPaginatedProduits();
  if(this.settings.fixedHeader){      
      document.getElementById('main-content').scrollTop = 0;
  }
  else{
      document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
  }
}
handlePageChange(event: number): void {
  this.page = event;
 this.produitService.getPaginatedProduits(this.userService.getUserDetails()._id, this.page).subscribe((res: any) => {
    this.produits = res.docs;
    this.count = res.totalDocs;
  });
}


handlePageSizeChange(event: any): void {
  this.pageSize = event.target.value;
  this.retrieveTutorials();
}

searchTitle(): void {
  this.page = 1;
  this.retrieveTutorials();
}

addProduit(){
  this.dialog.open(AddProduitComponent, {
    width: '400px',
    height: '550px',
  })
}
  ajouterUnProduitAuPanier(idProduit: any, prixProduit: any){
    const obj={
      id: idProduit,
      prix: prixProduit
    }
    if(this.voirSiProduitEstChoisi(idProduit)== false){
      this.produitAjouter.push(obj)
    }
    else{
      const tableauFiltrer= this.produitAjouter.filter(data=>{
        data.id == idProduit 
      })

      this.produitAjouter== tableauFiltrer
    }

  }
 voirSiProduitEstChoisi(idProduit: any): Boolean{
  for (let index = 0; index < this.produitAjouter.length; index++) {
    if(this.produitAjouter[index].id== idProduit){
        return true;
    }
    else{
      return false;
    }
  
}
 }

updateProduit(id){
  this.dialog.open(UpdateProduitComponent, {
    width: '400px',
    height: '550px',
    data: {id: id}
  })
}
deleteProduit(id){
  this.dialog.open(DeleteProduitComponent, {
  
    data: {id: id}
  })
}
}
