import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { PrintService } from '../services/print.service';
import { UserService } from '../services/user.service';
import { passwordUpdate } from '../validators/passwordUpdate';

@Component({
  selector: 'app-confirmation-update',
  templateUrl: './confirmation-update.component.html',
  styleUrls: ['./confirmation-update.component.scss']
})
export class ConfirmationUpdateComponent implements OnInit {

  etatPadding: boolean = true;
  user: any;

  constructor(private fb: FormBuilder, private userService: UserService, public print: PrintService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
  }

  controlForm = this.fb.group({
    code: ['', {
      validators: [
       Validators.required
     ]}
   ],
    newPassword: ['', Validators.required],
    confirmNewPassword: ['', Validators.required],
  }, 
  {validator: passwordUpdate})

  onSubmit(){
    this.etatPadding = false;
    const id = this.route.snapshot.paramMap.get('id');
    this.userService.getUserById(id).subscribe(res => {
      this.user = res;
      if(this.user.codes == this.code.value){
        this.userService.updatePassword(id, this.controlForm.value).subscribe(res => {
          Swal.fire(
            'Renitialisé!',
            'Votre mot de passe a été modifié avec success!!',
            'success'
          )
          this.router.navigate(['/login'])
        })
      }else{
        this.etatPadding = true;
        Swal.fire({
          title: 'Le code que vous avez saisis est incorect!!',
          width: 600,
          padding: '3em',
          background: '#fff',
          backdrop: `
            rgba(255,0,0,0.7)
           
          `
        })
      }
    })
  }


  getCodeError(){
    if(this.code.invalid && (this.code.dirty || this.code.touched)){
      if(this.code.errors.required){
        return 'Le code est requis!!';
      }else if(this.code.errors.pattern){
        return 'Code incorect!!';
      }
    }
  }
  
  getCodeSuccess(){
    if(this.code.valid){
      return true;
    }
  }

  getPasswordNewError(){
    if(this.newPassword.invalid && (this.newPassword.dirty || this.newPassword.touched)){
      if(this.newPassword.errors.required){
        return 'Le Mont de passe est requis.';
      }else if(this.newPassword.errors.minlength){
        return 'Au minimum 6 caracters.';
      }
    }
  }
  
  getPasswordNewSuccess(){
    if(this.newPassword.valid){
      return true;
    }
  }

  getPasswordConfirmError(){
    if(this.confirmNewPassword.invalid && (this.confirmNewPassword.dirty || this.confirmNewPassword.touched)){
      if(this.confirmNewPassword.errors.required){
        return 'Le Mont de passe est requis.';
      }else if(this.confirmNewPassword.errors.minlength){
        return 'Au minimum 6 caracters.';
      }
    }
  }
  
  getPasswordConfirmSuccess(){
    if(this.confirmNewPassword.valid){
      return true;
    }
  }

  get code(){
    return this.controlForm.get('code');
  }
  get newPassword(){
    return this.controlForm.get('newPassword');
  }
  get confirmNewPassword(){
    return this.controlForm.get('confirmNewPassword');
  }


}
