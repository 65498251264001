import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from '../backend';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class CommentaireService {
  ap = `${baseUrl}zones`;
  api = `${baseUrl}produits`;

  constructor(private http: HttpClient) { }
  
  public getCommentaireByProduitId(commentaire: any): Observable<any>{
    return this.http.post<any>(`${this.ap}/getCommentaireByProduitId`, commentaire).pipe(
      retry(3)
    );
  }


  public upload(data) {
    return this.http.post<any>(`${this.api}/image`, data, {
      // reportProgress: true,
      // observe: 'events' 
    }).pipe(
      retry(3),
    );
  }

  getImage(link: any) {
    return this.http.get(`${this.ap}/getfile/` + link);
  }

  public addCommentaire(commentaire: any): Observable<any>{
    return this.http.post<any>(`${this.ap}/addCommentaire`, commentaire).pipe(
      retry(3)
    );
  }

  public updateCommentaire(id: any, commentaire): Observable<any>{
    return this.http.put<any>(`${this.ap}/updateCommentaire/${id}`, commentaire).pipe(
      retry(3)
    );
  }

  public getPaginatedCommentaire(): Observable<any>{
    return this.http.get<any>(`${this.ap}/getPaginatedCommentaire`).pipe(
      retry(3)
    );
  }
  public getAllCommentaire(): Observable<any>{
    return this.http.get<any>(`${this.ap}/getAllCommentaire`).pipe(
      retry(3)
    );
  }

  public deleteCommentaire(id: any): Observable<any>{
    return this.http.delete<any>(`${this.ap}/deleteCommentaire/${id}`).pipe(
      retry(3)
    );
  }

  public getCommentaireById(id: any): Observable<any>{
    return this.http.get<any>(`${this.ap}/getCommentaireById/${id}`).pipe(
      retry(3)
    );
  }
}
