import { RegionService } from 'src/app/services/region.service';
import { SnackBarService } from './../../services/snack-bar.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommuneService } from 'src/app/services/commune.service';
import { UpdateRegionComponent } from '../update-region/update-region.component';

@Component({
  selector: 'app-update-commune',
  templateUrl: './update-commune.component.html',
  styleUrls: ['./update-commune.component.scss']
})
export class UpdateCommuneComponent implements OnInit {
  commune: any;
  public region: any;

  etatPadding: boolean = true;
  communeForm: any  
    constructor(private regionService: RegionService,private fb: FormBuilder, private communeService: CommuneService, private _snckBar: SnackBarService,public dialogRef: MatDialogRef<UpdateRegionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
      this.communeForm =  this.fb.group({
        region_id:['',  {
          validators: [
          Validators.required,
        ]},],
        libelle: ['',  {
          validators: [
          Validators.required,
        ]},],
      });
     }

  ngOnInit(): void {
    this.getAllRegion()
    this.getCommuneById();
    
  }
  public getAllRegion(){
    this.regionService.getAllRegion().subscribe(res => {
      this.region = res;
    });
  }
  getCommuneById(){
    this.communeService.getCommuneById(this.data.id).subscribe(res => {
      this.commune = res;
      this.initialiseForms();
    })
  }

 

 


 
  onSubmit(){
    this.etatPadding = false;
    console.log(this.communeForm.value)
        this.communeService.updateCommune(this.data.id, this.communeForm.value).subscribe(res => {
          this._snckBar.openSnackBar("Modification Reuissie!!", "Fermer");
          this.dialogRef.close();
          this.etatPadding = false;
        })
   
  }

  private initialiseForms(){
    this.communeForm.patchValue({
      region_id: this.commune[0].region_id ? this.commune[0].region_id : '',
      libelle: this.commune[0].libelle ? this.commune[0].libelle : '',
    })
  }

  get region_id(){
    return this.communeForm.get('region_id');
  }
   get libelle(){
     return this.communeForm.get('libelle');
   }
}
