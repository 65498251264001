import { SnackBarService } from './../../services/snack-bar.service';
import { StatutService } from './../../services/statut.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-statut',
  templateUrl: './delete-statut.component.html',
  styleUrls: ['./delete-statut.component.scss']
})
export class DeleteStatutComponent {

  constructor(public dialogRef: MatDialogRef<DeleteStatutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private statutService: StatutService, private _snackBar: SnackBarService) { }

  ngOnInit(): void {
  }
  onClickYes(){
    this.statutService.deleteStatut(this.data.id).subscribe(res => {
      this.dialogRef.close();
      this._snackBar.openSnackBar("Supression Reuissie!!", "Fermer");
    });
  }

  onClickNo(){
    this.dialogRef.close();
  }
}
