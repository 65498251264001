export const Produit =[
    {
        "id": 1,
        "packageId": 1,
        "libelle":  "Sable"
    },
    {
        "id": 2,
        "packageId": 1,
        "libelle":  "Ciment"
    },
    {
        "id": 3,
        "packageId": 1,
        "libelle":  "Granite"
    },

   /* {
        "id": 4,
        "packageId": 2,
        "libelle":  "Ciment"
    },

    
    {
        "id": 5,
        "packageId": 3,
        "libelle":  "Ciment"
    },
    {
        "id": 6,
        "packageId": 3,
        "libelle":  "Sable"
    },
    {
        "id": 7,
        "packageId": 3,
        "libelle":  "Granite"
    },

*/
    
]
