import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../backend';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommuneService {
  ap = `${baseUrl}zones`;
  
  constructor(private http: HttpClient) { }

  public addCommune(commune: any): Observable<any>{
    return this.http.post<any>(`${this.ap}/addCommune`, commune).pipe(
      retry(3)
    );
  }
  public getAllCommune(): Observable<any>{
    return this.http.get<any>(`${this.ap}/getAllCommune`).pipe(
      retry(3)
    );
  }
  
  public updateCommune(id: any, commune): Observable<any>{
    return this.http.put<any>(`${this.ap}/updateCommune/${id}`, commune).pipe(
      retry(3)
    );
  }
  
  public getCommuneByRegionId(id: any): Observable<any>{
    return this.http.get<any>(`${this.ap}/getCommuneByRegionId/${id}`).pipe(
      retry(3)
    );
  }

  public deleteCommune(id: any): Observable<any>{
    return this.http.delete<any>(`${this.ap}/deleteCommune/${id}`).pipe(
      retry(3)
    );
  }

  public getCommuneById(id: any): Observable<any>{
    return this.http.get<any>(`${this.ap}/getCommuneById/${id}`).pipe(
      retry(3)
    );
  }
}
