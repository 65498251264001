import { DeleteCommuneComponent } from './../../papages/delete-commune/delete-commune.component';
import { Subscription, timer } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateCommuneComponent } from './../update-commune/update-commune.component';
import { CommuneService } from './../../services/commune.service';
import { AddCommuneComponent } from './../add-commune/add-commune.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { PrintService } from 'src/app/services/print.service';
import { Commune } from './commune.model'; 
import { ViewPlageComponent } from 'src/app/view-plage/view-plage.component';
import { ZoneComponent } from '../zone/zone.component';
import { AllQuartierComponent } from 'src/app/all-quartier/all-quartier.component';

@Component({
  selector: 'app-all-commune',
  templateUrl: './all-commune.component.html',
  styleUrls: ['./all-commune.component.scss']
})
export class AllCommuneComponent {
  public commune: Commune[]
  displayedColumns: string[] = ['libelle', 'region', 'pays','Action','a'];

  dataSource = new MatTableDataSource<PeriodicElement>(null);
  subscription: Subscription;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  
  public settings: Settings;
  constructor(public print: PrintService, private dialog: MatDialog,public appSettings:AppSettings,private communeService:CommuneService) {
   this.settings = this.appSettings.settings; 
  }
  
  ngOnInit(): void {
    this.subscription =  timer(0, 3000).subscribe(res => {
      this.getAllOrders()
    });
  
  }
  getCommune(){
   this.communeService.getAllCommune().subscribe(res => {
     this.commune = res;
   });
  }
  getAllOrders() {
    this.communeService.getAllCommune().subscribe((data: any) => {
      this.dataSource = new MatTableDataSource <PeriodicElement> (data); //pass the array you want in the table
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      return data
    })}
  
  addCommune(){
    this.dialog.open(AddCommuneComponent, {
    
     
    })
  }
  deleteCommune(id){
    this.dialog.open(DeleteCommuneComponent, {
      data: {id: id}
     
    })
  }
  upadteCommune(id){
    this.dialog.open(UpdateCommuneComponent, {
      data: {id: id}
     
    })
  }
  viewPlage(id){
    this.dialog.open(ViewPlageComponent, {
      width: '800px',
      height: '380px',
      data: {id: id}
     
    })
  }
  viewQuartier(id){
    this.dialog.open(AllQuartierComponent, {
      width: '800px',
      height: '380px',
      data: {id: id}
     
    })
  }
  
  
}
export interface PeriodicElement {
  libelle: string
  region: string
  pays: string
  Action: string
  a
  
  }