export const Passport =[
    {
        "id": 1,
        "libelle":  "Briquetérie",
        "image": ""
    },
    {
        "id": 2,
        "libelle":  "Pdv",
        "image": ""
    },
    {
        "id": 3,
        "libelle":  "Salarié",
        "image": ""
    }


]