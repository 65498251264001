import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../backend';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  api = `${baseUrl}produits`;
  ap = `${baseUrl}zones`;
  
  constructor(private http: HttpClient) { }

  public addRegion(region: any): Observable<any>{
    return this.http.post<any>(`${this.ap}/addRegion`, region).pipe(
      retry(3)
    );
  }
  public getAllRegion(): Observable<any>{
    return this.http.get<any>(`${this.ap}/getAllRegion`).pipe(
      retry(3)
    );
  }
  public updateRegion(id: any, region): Observable<any>{
    return this.http.put<any>(`${this.ap}/updateRegion/${id}`, region).pipe(
      retry(3)
    );
  }
 
  public getRegionByPaysId(id: any): Observable<any>{
    return this.http.get<any>(`${this.ap}/getRegionByPaysId/${id}`).pipe(
      retry(3)
    );
  }

  public deleteRegion(id: any): Observable<any>{
    return this.http.delete<any>(`${this.ap}/deleteRegion/${id}`).pipe(
      retry(3)
    );
  }

  public getRegionById(id: any): Observable<any>{
    return this.http.get<any>(`${this.ap}/getRegionById/${id}`).pipe(
      retry(3)
    );
  }
}

