import { Subscription, timer } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UpdatePaysComponent } from './../update-pays/update-pays.component';
import { DeletePaysComponent } from './../delete-pays/delete-pays.component';
import { PaysService } from './../../services/pays.service';
import { AddPaysComponent } from './../add-pays/add-pays.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { PrintService } from 'src/app/services/print.service';
import { Pays } from './pays.model.ts';

@Component({
  selector: 'app-all-pays',
  templateUrl: './all-pays.component.html',
  styleUrls: ['./all-pays.component.scss']
})
export class AllPaysComponent {
public pays: Pays[]
dataSource = new MatTableDataSource<PeriodicElement>(null);
subscription: Subscription;

@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort, { static: false }) sort: MatSort;
displayedColumns: string[] = ['libelle', 'action', 'a'];

public settings: Settings;
constructor(public print: PrintService, private dialog: MatDialog,public appSettings:AppSettings,private paysService:PaysService) {
 this.settings = this.appSettings.settings; 
}

ngOnInit(): void {

  this.subscription =  timer(0, 3000).subscribe(res => {
    this.getAllOrders();
  });

}
getPays(){
 this.paysService.getAllPays().subscribe(res => {
   this.pays = res;
 });
}
getAllOrders() {
  this.paysService.getAllPays().subscribe((data: any) => {
    this.dataSource = new MatTableDataSource <PeriodicElement> (data); //pass the array you want in the table
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    return data
  })}

addPays(){
  this.dialog.open(AddPaysComponent, {
  
   
  })
}
deletePays(id){
  this.dialog.open(DeletePaysComponent, {
    data: {id: id}
   
  })
}
updatePays(id){
  this.dialog.open(UpdatePaysComponent, {
    data: {id: id}
   
  })
}


}
export interface PeriodicElement {
libelle: string;

action: string;
a: string;

}