import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Package } from 'src/staticData/package';
import {  } from '@angular/material/dialog';
import { ProduitPackageComponent } from '../produit-package/produit-package.component';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {
  public package= []
  public isReady= false
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getPackageByPassportId(this.data.id)
  }


  public getPackageByPassportId(idPassport: number){
    
    
    for (let i = 0; i < Package.length; i++) {
      if(Package[i].passportId== idPassport){
        this.package[i]= Package[i]
      }
    }
    let c= this.package.filter((item: any)=> item != undefined)
    this.package= c
    this.isReady= true
  }

  viewProduits(idPackage: any){
    this.dialog.open(ProduitPackageComponent, {
      data: {id: idPackage},
      width: '400px',
      height: '380px',
     
    })
  }
}
