import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, timer } from 'rxjs';
import { AddChauffeurComponent } from 'src/app/add-chauffeur/add-chauffeur.component';
import { AddStockComponent } from 'src/app/add-stock/add-stock.component';
import { AddZoneComponent } from 'src/app/add-zone/add-zone.component';
import { DeleteChauffeurComponent } from 'src/app/delete-chauffeur/delete-chauffeur.component';
import { DeleteStockComponent } from 'src/app/delete-stock/delete-stock.component';
import { CommandeService } from 'src/app/services/commande.service';
import { ZoneService } from 'src/app/services/zone.service';
import { TransportComponent } from 'src/app/transport/transport.component';
import { UpdateChauffeurComponent } from 'src/app/update-chauffeur/update-chauffeur.component';
import { UpdateStockComponent } from 'src/app/update-stock/update-stock.component';
import { ViewPlageComponent } from 'src/app/view-plage/view-plage.component';

@Component({
  selector: 'app-all-chauffeur',
  templateUrl: './all-chauffeur.component.html',
  styleUrls: ['./all-chauffeur.component.scss']
})
export class AllChauffeurComponent implements OnInit {
  
  subscription: Subscription;
  displayedColumns: string[] = ['nom','telephone','email','transport','action'];
  constructor(private zoneService:ZoneService,private stockService:CommandeService,private dialog: MatDialog){}
  public isReady= false
  public count= 0
  dataSource = new MatTableDataSource<PeriodicElement>(null);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngAfterViewInit() {
    

  }
  ngOnInit(): void {
    this.subscription =  timer(0, 3000).subscribe(res => {
      this.getAllOrders();
      this.count++
    });
  
  }

  getAllOrders() {
    this.zoneService.allChauffeur().subscribe((data: any) => {
      this.dataSource = new MatTableDataSource <PeriodicElement> (data); //pass the array you want in the table
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      if(this.count== 1){
        this.isReady= true
      }
      return data
    })}


    addZone(){
      this.dialog.open(AddZoneComponent, {
        width: '400px',
        height: '380px',
      })
    }
    addStock(){
      this.dialog.open(AddChauffeurComponent, {
        width: '400px',
        height: '400px',
       
      })
    }
    viewPlage(id){
      this.dialog.open(ViewPlageComponent, {
        data: {id: id}
       
      })
    }
    updateChauffeur(id){
      this.dialog.open(UpdateChauffeurComponent, {
        width: '400px',
        height: '380px',
        data: {id: id}
      })
    }
    deleteChauffeur(id){
      this.dialog.open(DeleteChauffeurComponent, {
       
        data: {id: id}
      })
    }
    transport(id){
      this.dialog.open(TransportComponent, {
        width: '800px',
        height: '380px',
        data: {id: id}
      })
    }
    

}

export interface PeriodicElement {
  nom: string;
  telephone: string;
  email: string;
  action: string;
  a: string;
 
 
 
 
}



