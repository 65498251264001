import { RegionService } from './../../services/region.service';
import { UserService } from 'src/app/services/user.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { CommuneService } from 'src/app/services/commune.service';
import { Settings } from 'src/app/app.settings.model';
import { AppSettings } from 'src/app/app.settings';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroupDirective, NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-commune',
  templateUrl: './add-commune.component.html',
  styleUrls: ['./add-commune.component.scss']
})
export class AddCommuneComponent {
 
  selectedFiles?: FileList;
  selectedFileNames: string[] = [];

  progressInfos: any[] = [];
  message: string[] = [];

  previews: string[] = [];
  imageInfos?: Observable<any>;
  settings: Settings;
  granite = true;
  fileData: File = null;
  bam: boolean = false;
  etatPadding: boolean = true;
  public region: any;
  public communeForm: any
  constructor(
    private regionService: RegionService,
    private _snckBar: SnackBarService,
    public dialogRef: MatDialogRef<AddCommuneComponent>,
    public userService:UserService,
    public appSettings:AppSettings,
    private fb: FormBuilder,
    private communeService: CommuneService)
     {
    this.settings = this.appSettings.settings;  
    this.communeForm =  this.fb.group({
      region_id:['',  {
        validators: [
        Validators.required,
      ]},],
      libelle: ['',  {
        validators: [
        Validators.required,
      ]},],
      
     
     
    });   
  }
  ngOnInit(): void {
   this.getAllRegion()
  }

  public getAllRegion(){
    this.regionService.getAllRegion().subscribe(res => {
      this.region = res;
    });
  }

  
  onSubmit(){
    //  this.etatPadding = false;
         this.communeService.addCommune(this.communeForm.value).subscribe(res => {
           this.dialogRef.close();
           this._snckBar.openSnackBar("commune Ajouté!!", "Fermer");
          //  this.etatPadding = true;

         });
   
    
  }
  close(): void {
    this.dialogRef.close();
  }
}
