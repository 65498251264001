import { PaysService } from 'src/app/services/pays.service';
import { UserService } from 'src/app/services/user.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { RegionService } from 'src/app/services/region.service';
import { Settings } from 'src/app/app.settings.model';
import { AppSettings } from 'src/app/app.settings';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroupDirective, NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-region',
  templateUrl: './add-region.component.html',
  styleUrls: ['./add-region.component.scss']
})
export class AddRegionComponent {

  selectedFiles?: FileList;
  selectedFileNames: string[] = [];

  progressInfos: any[] = [];
  message: string[] = [];

  previews: string[] = [];
  imageInfos?: Observable<any>;
  settings: Settings;
  granite = true;
  fileData: File = null;
  bam: boolean = false;
  etatPadding: boolean = true;
  public pays: any;
  public regionForm: any
  constructor(
    private paysService: PaysService,
    private _snckBar: SnackBarService,
    public dialogRef: MatDialogRef<AddRegionComponent>,
    public userService:UserService,
    public appSettings:AppSettings,
    private fb: FormBuilder,
    private regionService: RegionService)
     {
    this.settings = this.appSettings.settings;  
    this.regionForm =  this.fb.group({
      pays_id:['',  {
        validators: [
        Validators.required,
      ]},],
      libelle: ['',  {
        validators: [
        Validators.required,
      ]},],
      
     
     
    });   
  }
  ngOnInit(): void {
   this.getAllPays()
  }

  public getAllPays(){
    this.paysService.getAllPays().subscribe(res => {
      this.pays = res;
    });
  }

  
  onSubmit(){
    //  this.etatPadding = false;
    console.log(this.regionForm.value)
         this.regionService.addRegion(this.regionForm.value).subscribe(res => {
           this.dialogRef.close();
           this._snckBar.openSnackBar("region Ajouté!!", "Fermer");
          //  this.etatPadding = true;

         });
   
    
  }
  close(): void {
    this.dialogRef.close();
  }
}
