import { UserService } from 'src/app/services/user.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProduitService } from '../services/produit.service';
import { RegionService } from '../services/region.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ZoneService } from '../services/zone.service';

@Component({
  selector: 'app-update-zone',
  templateUrl: './update-zone.component.html',
  styleUrls: ['./update-zone.component.scss']
})
export class UpdateZoneComponent implements OnInit {


  zones: any[] = [];

  produit: any[] = [];
  regions: any[] = [];
  verifie_id = 0;
  zone: any;
  status_region = 0;

  categories: any[] = [];
  verifie_region: any[] = [];
  etatPadding: boolean = true;

  constructor(private fb: FormBuilder,private userService: UserService, private zoneServices: ZoneService,private regionService:RegionService, private produitService: ProduitService, private _snckBar: SnackBarService,public dialogRef: MatDialogRef<UpdateZoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.getProduit();
    this.getZones();
    this.getRegion();
  }
  

  getRegion(){
    this.regionService.getAllRegion().subscribe(res => {
      this.regions = res;
    });
   }
  selectRegion(event: any){
   
    this.verifie_id = event.target.value;
   
    this.zoneServices.getAllCommuneByRegion(this.verifie_id).subscribe(res => {
      this.verifie_region= res;
    console.log("regions bella",this.verifie_region);
   
    })
      
       // this.zoneCalcule = this.lieux[0].produit_id?.prix;    
  
  }
  getZones(){
    this.zoneServices.getZone(this.data.id).subscribe(res => {
      this.zone = res;
      this.initialiseForms();
      console.log("ma zone",this.zone);
    
    })
  }
  getProduit(){
    this.produitService.getProduits(this.userService.getUserDetails()._id).subscribe(res => {
      this.produit = res;
      
    })
  }

  

  zoneForm = this.fb.group({

    
    
    commune_id: ['', []],
    localite: ['', [Validators.required]],
    
  });

  

  onSubmit(){
    this.zoneServices.updateZone(this.data.id,this.zoneForm.value).subscribe(res => {
        this._snckBar.openSnackBar("Modification Reuissie!!", "Fermer");
        this.dialogRef.close();
        this.etatPadding = false;

      });
    
  }

  private initialiseForms(){
    this.zoneForm.patchValue({
      commune_id: this.zone[0].commune_id ? this.zone[0].commune_id : '',
      localite: this.zone[0].localite ? this.zone[0].localite : '',
    })
  }


 
  
  
 
  get commune_id(){
    return this.zoneForm.get('commune_id');
  }
  get localite(){
    return this.zoneForm.get('localite');
  }
 


}
