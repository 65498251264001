import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-update-categorie',
  templateUrl: './update-categorie.component.html',
  styleUrls: ['./update-categorie.component.scss']
})
export class UpdateCategorieComponent implements OnInit {

  granite = true;
  produit: any;
  fileData: File = null;
  bam: boolean = false;
  etatPadding: boolean = true;
  selectedFiles?: FileList;
  selectedFileNames: string[] = [];

  progressInfos: any[] = [];
  message: string[] = [];

  previews: string[] = [];

  constructor(private fb: FormBuilder,public userService:UserService, private produitService: ProduitService, private _snckBar: SnackBarService,public dialogRef: MatDialogRef<UpdateCategorieComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.getCategorie();
  }

  getCategorie(){
    this.produitService.getCategories(this.data.id).subscribe(res => {
      this.produit = res;
      console.log('I AM A RESULT ', this.produit);
      this.initialiseForms();
    })
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    if (fileInput.target.files.length > 0) {
      const file = fileInput.target.files[0];
      this.categorieForm.get('image').setValue(file);
      this.bam = true;
    }
  }

  categorieForm =  this.fb.group({
   
    nom: ['', []],
    distributeur_id: [''],
    image: [''],
  });

  select(event){
    if(event.target.value == 'GRANITE' ||event.target.value == 'BLOC' ||event.target.value == 'CIMENT'||event.target.value == 'GRAVIER' ||event.target.value == 'FER A BETON' ||event.target.value == 'SABLE'||event.target.value == 'LATERITE' ){
      this.granite = true;
    }else{
      this.granite = false;
    }
  }
  value = 'Clear me';
  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFileNames = [];
    this.selectedFiles = event.target.files;

    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          console.log(e.target.result);
          this.previews.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedFiles[i]);

        this.selectedFileNames.push(this.selectedFiles[i].name);
      }
    }
  }
  onSubmit(){
    this.etatPadding = false;

    const formData = new FormData();
    formData.append('file', this.image.value);
    console.log('FORM DATA', this.image);
    
    if(this.bam){
      this.produitService.upload(formData).subscribe(res => {
        this.image.setValue(res.path);
        this.distributeur_id.setValue(this.userService.getUserDetails()._id);
        this.produitService.updateCategorie(this.data.id, this.categorieForm.value).subscribe(res => {
          this._snckBar.openSnackBar("Modification Reuissie!!", "Fermer");
          this.dialogRef.close();
          this.etatPadding = false;

        });
      });
    }else{
      this.image.setValue(this.produit.image);
      this.distributeur_id.setValue(this.userService.getUserDetails()._id);
      this.produitService.updateCategorie(this.data.id, this.categorieForm.value).subscribe(res => {
        this._snckBar.openSnackBar("Modification Reuissie!!", "Fermer");
        this.dialogRef.close();
        this.etatPadding = false;

      });
    }
  }

  private initialiseForms(){
    this.categorieForm.patchValue({
      nom: this.produit.nom ? this.produit.nom : '',
      image: this.produit.image ? this.produit.image : '',
      
    })
  }

  
  get nom(){
    return this.categorieForm.get('nom');
  }
  get variation(){
    return this.categorieForm.get('variation');
  }
  get image(){
    return this.categorieForm.get('image');
  }
  get distributeur_id(){
    return this.categorieForm.get('distributeur_id');
  }

}
