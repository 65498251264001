import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, timer } from 'rxjs';
import { AddPlageComponent } from 'src/app/add-plage/add-plage.component';
import { AddZoneComponent } from 'src/app/add-zone/add-zone.component';
import { DeleteZoneComponent } from 'src/app/delete-zone/delete-zone.component';
import { ZoneService } from 'src/app/services/zone.service';
import { UpdateZoneComponent } from 'src/app/update-zone/update-zone.component';
import { Settings } from '../app.settings.model';
import { DeletePlageComponent } from '../delete-plage/delete-plage.component';
import { UserService } from '../services/user.service';


@Component({
  selector: 'app-all-quartier',
  templateUrl: './all-quartier.component.html',
  styleUrls: ['./all-quartier.component.scss']
})
export class AllQuartierComponent implements OnInit {
  subscription: Subscription;
  page = 1;
  count = 0;
  pageSize = 6;
  pageSizes = [6, 12, 18];
  title = '';
  public settings: Settings;
  public zone: []=[]
  //public zones: []=[]
  public zones: any[] = []; 
  public isReady= false
  displayedColumns: string[] = [ 'libelle','action'];
    constructor( @Inject(MAT_DIALOG_DATA) public data: any,private zoneService:ZoneService,private dialog: MatDialog,private userService: UserService){}
 


    dataSource = new MatTableDataSource<PeriodicElement>(null);

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    ngAfterViewInit() {
      
  
    }
    ngOnInit(): void {
       
      this.subscription =  timer(0, 3000).subscribe(res => {
        //  this.getAllOrders();
        this.allzones()
      });
    
    }
    allzones() {
      this.zoneService.allzones(this.data.id).subscribe((data: any) => {
        this.dataSource = new MatTableDataSource <PeriodicElement> (data); //pass the array you want in the table
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        return data
      })}
  
    getZones(){
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
     this.zoneService.getPaginatedZones(params.page).subscribe((res : any)=> {
          this.zone = res.docs;
          this.count = res.totalDocs;
          this.page = params.page;
          this.isReady= true
     });
    }
  
    getRequestParams(searchTitle: string  , page: number, pageSize: number): any {
      let params: any = {};
    
      if (searchTitle) {
        params[`title`] = searchTitle;
      }
  
      if (page) {
        params[`page`] = page - 1;
      }
    
      if (pageSize) {
        params[`size`] = pageSize;
      }
      return params;
    }
  
    handlePageChange(event: number): void {
      this.isReady= false
      this.page = event;
      this.zoneService.getPaginatedZones(this.page).subscribe((res : any)=> {
        this.zone = res.docs;
        this.count = res.totalDocs;
        this.isReady= true
  
      });
    }
  
    retrieveTutorials(): void {
      const params = this.getRequestParams(this.title, this.page, this.pageSize);
       this.zoneService.getPaginatedZones(this.page).subscribe((res : any)=> {
             this.zone = res.docs;
             this.count = res.totalDocs;
             this.page = params.page;
        });
    }
  
    handlePageSizeChange(event: any): void {
      this.pageSize = event.target.value;
      this.retrieveTutorials();
    }
  
    public onPageChanged(event){
      this.page = event;
      this.retrieveTutorials();
      if(this.settings.fixedHeader){      
          document.getElementById('main-content').scrollTop = 0;
      }
      else{
          document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
      }
    } 
  
    getAllOrders() {
      this.zoneService.getZoness().subscribe((data: any) => {
        console.log(data)
        this.dataSource = new MatTableDataSource <PeriodicElement> (data); //pass the array you want in the table
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        return data
      })}
  
  
      addZone(id){
        this.dialog.open(AddZoneComponent, {
          width: '400px',
           height: '350px',
           data: {id: id}
        })
      }
      addPlage(id){
        this.dialog.open(AddPlageComponent, {
          width: '400px',
          height: '380px',
          data: {id: id}
        })
      }
   
      updateZone(id){
        this.dialog.open(UpdateZoneComponent, {
          width: '400px',
          height: '380px',
          data: {id: id}
        })
      }
      deleteZone(id){
        this.dialog.open(DeleteZoneComponent, {
         
          data: {id: id}
        })
      }
  
  }
  
  export interface PeriodicElement {
    libelle: string;
   
    action: string;

  
  }
