import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appImageLoaded]'
})
export class ImageLoadedDirective {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    const imgElement = this.elementRef.nativeElement;

    // Créer un spinner MatProgressSpinner
    const spinnerElement = this.renderer.createElement('mat-spinner');
    this.renderer.addClass(spinnerElement, 'custom-spinner');

    // Insérer le spinner avant l'image
    this.renderer.insertBefore(imgElement.parentNode, spinnerElement, imgElement);

    // Masquer l'image par défaut
    this.renderer.setStyle(imgElement, 'display', 'none');

    // Écouter l'événement de chargement de l'image
    imgElement.onload = () => {
      // Supprimer le spinner
      this.renderer.removeChild(imgElement.parentNode, spinnerElement);

      // Afficher l'image une fois qu'elle est totalement chargée
      this.renderer.setStyle(imgElement, 'display', 'block');
    };
  }
}
