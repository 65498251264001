export const Package =[
    {
        "id": 1,
        "passportId": 1,
        "libelle":  "Alpha"
    },
    {
        "id": 2,
        "passportId": 2,
        "libelle":  "Alpha"
    },
    {
        "id": 3,
        "passportId": 3,
        "libelle":  "Salarié"
    }
]