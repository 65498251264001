import { SnackBarService } from './../../services/snack-bar.service';
import { RegionService } from './../../services/region.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-region',
  templateUrl: './delete-region.component.html',
  styleUrls: ['./delete-region.component.scss']
})
export class DeleteRegionComponent {

  constructor(public dialogRef: MatDialogRef<DeleteRegionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private regionService: RegionService, private _snackBar: SnackBarService) { }

  ngOnInit(): void {
  }
  onClickYes(){
    this.regionService.deleteRegion(this.data.id).subscribe(res => {
      this.dialogRef.close();
      this._snackBar.openSnackBar("Supression Reuissie!!", "Fermer");
    });
  }

  onClickNo(){
    this.dialogRef.close();
  }
}
