import { PaysService } from './../../services/pays.service';
import { SnackBarService } from './../../services/snack-bar.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-pays',
  templateUrl: './update-pays.component.html',
  styleUrls: ['./update-pays.component.scss']
})
export class UpdatePaysComponent implements OnInit {
  pays: any;
  etatPadding: boolean = true;
  
  constructor(private fb: FormBuilder, private paysService: PaysService, private _snckBar: SnackBarService,public dialogRef: MatDialogRef<UpdatePaysComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.getPaysById();
  }

  getPaysById(){
    this.paysService.getPaysById(this.data.id).subscribe(res => {
      this.pays = res;
      this.initialiseForms();
    })
  }

 

  paysForm =  this.fb.group({
    libelle: ['',  {
      validators: [
      Validators.required
      ]},],
  });


 
  onSubmit(){
    this.etatPadding = false;
        this.paysService.updatePays(this.data.id, this.paysForm.value).subscribe(res => {
          this._snckBar.openSnackBar("Modification Reuissie!!", "Fermer");
          this.dialogRef.close();
          this.etatPadding = false;
        })
   
  }

  private initialiseForms(){
    this.paysForm.patchValue({
      libelle: this.pays[0].libelle ? this.pays[0].libelle : '',
    })
  }

  
   get libelle(){
     return this.paysForm.get('libelle');
   }
 

}
